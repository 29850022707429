import React, { useState, useEffect } from "react";
import API from "../../../api/apiService";
import { ENDPOINTS } from "../../../api/apiRoutes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../index.css";
import search from "../../../assets/images/search-lg.png";
import AddCouponCode from "../../../components/AddCouponCodeModal/AddCouponCode";
import EditCouponCode from "../../../components/EditCouponCodeModal/EditCouponCode";
import ReactPaginate from "react-paginate";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "./Coupons_Code.css"
import { RotatingLines } from 'react-loader-spinner';

function Coupons_Code() {
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(false);

  const handleOpenCouponModal = () => {
    setIsCouponModalOpen(true);
  };

  const handleCloseCouponModal = () => {
    setIsCouponModalOpen(false);
  };

  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredItems.slice(offset, offset + itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleCouponSubmit = (couponData) => {
    setData((prevData) => [...prevData, couponData]);
    handleCloseCouponModal();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await API.get(ENDPOINTS.GET_ALLCOUPONCODE, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(response.coupons);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
      finally {
        setLoading(false); 
      }
    };

    fetchCoupons();
  }, []);

  useEffect(() => {
    const filteredData = data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filteredData);
  }, [searchTerm, data]);

  const deleteCoupon = async (couponId) => {
    try {
      const token = localStorage.getItem("token");
      await API.deleteResource(`${ENDPOINTS.DELETE_ALLCOUPONS}/${couponId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.filter((item) => item._id !== couponId));
      toast.success("Coupon deleted successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error deleting coupon:", error);
      toast.error("Failed to delete the coupon.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const openEditModal = (coupon) => {
    setSelectedCoupon(coupon);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedCoupon(null);
  };

  const handleUpdateCoupon = (updatedCoupon) => {
    setData((prevData) =>
      prevData.map((item) =>
        item._id === updatedCoupon._id ? updatedCoupon : item
      )
    );
    closeEditModal();
  };

  return (
    <>
      <ToastContainer />
      {isCouponModalOpen && (
        <AddCouponCode
          handleCloseModal={handleCloseCouponModal}
          handleCouponSubmit={handleCouponSubmit}
        />
      )}

      {isEditModalOpen && selectedCoupon && (
        <EditCouponCode
          handleCloseModal={closeEditModal}
          bannerId={selectedCoupon._id}
          token={localStorage.getItem("token")}
          bannerData={selectedCoupon}
          onUpdateBanner={handleUpdateCoupon}
        />
      )}

      <div className="md:px-10 px-8 py-6">
        <div className="flex justify-between items-center gap-4 my-4">
          <div className="flex flex-col gap-2">
            <p className="text-[#020617] text-4xl BBoldFont">Coupons Code</p>
            <p className="text-[#696969] text-base NormalFont">
              Here is a quick overview about your Coupons Code
            </p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={handleOpenCouponModal}
              className="bg-[#003E00] text-sm inter-Semi text-white p-[10px] rounded-md"
            >
              Add Coupon Code
            </button>
          </div>
        </div>

        <div className="flex md:flex-row flex-col justify-between items-center gap-4 mt-10">
          <div>
            <div className="border border-gray-300 flex p-3 rounded-lg gap-2 items-center">
              <img src={search} alt="search" />
              <input
                type="text"
                placeholder="Search"
                className="md:w-[280px] outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>

        {loading ? (
            <div className="flex justify-center items-center">
            <RotatingLines
              height="80"
              width="80"
              color="#003E00"
              ariaLabel="loading"
            />
            </div>
          ) : (
        <div className="my-10">
          <div className="overflow-x-auto">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #EAECF0",
                borderRadius: "20px",
              }}
            >
              <thead className="bg-gray-50">
                <tr className="border-b">
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Coupon Name
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">Value</p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Max Amount
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Min Amount
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}></th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr className="border-b" key={item._id}>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.name}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.value}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.maxAmount}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.minAmount}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <div className="flex gap-5">
                        <button onClick={() => deleteCoupon(item._id)}>
                          <MdDelete color="#003E00" size={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            breakClassName={"page-item"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
          />
        </div>
          )}
      </div>
    </>
  );
}

export default Coupons_Code;
