import React, { useEffect, useState } from "react";
import img1 from "../../assets/images/greenvegi.png";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINTS } from "../../api/apiRoutes";
import API from "../../api/apiService";
import { CloudCog } from "lucide-react";
import OrderReturn from "../OrderReturn/OrderReturn";

function OrderDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const res = await API.get(`${ENDPOINTS.GET_ORDERBYID}/${id}`);
        setOrder(res); 
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to fetch order details.");
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);


  return (
    <>
      <div
        className="w-full md:h-[40vh] h-[30vh]"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="p-4">
          <IoArrowBackCircleOutline
            size={50}
            color="black"
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
        </div>
      </div>
  
      <div className="p-2 flex items-center justify-center">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : order ? (
          <div className="flex md:flex-row flex-col md:w-[50vw] w-full gap-8 justify-between py-10">
          <div>
          <h1 className="text-3xl font-bold mb-4">Order Details</h1>
            <h2 className="font-semibold text-xl">Payment Info:</h2>
            <p><span className="font-semibold">Type:</span> {order.data.paymentInfo?.type}</p>
  
            {/* Cart Items */}
            <h2 className="font-semibold mt-4 text-xl">Cart Items:</h2>
            <ul>
              {(order?.data.cart || []).map((item) => (
                <li key={item._id} className="mb-2">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-32 h-32 rounded "
                  />
                  <p> <span className="font-semibold">Name:</span> {item.name}</p>
                  <p> <span className="font-semibold">Quantity:</span> {item.quantity}</p>
                  <p><span className="font-semibold">Price:</span> €{item.price}</p>
                </li>
              ))}
            </ul>

          </div>

          <div className="mt-10">
           
  
            <h2 className="font-semibold mt-4 text-xl">Shipping Address:</h2>
            <p> <span className="font-semibold">Full Name: </span>{order.data.shippingAddress?.Address?.fullName}</p>
            <p> <span className="font-semibold">Phone Number:</span> {order.data.shippingAddress?.Address?.phoneNumber}</p>
            <p> <span className="font-semibold">Street:</span> {order.data.shippingAddress?.Address?.street}</p>
            <p><span className="font-semibold">State:</span>{order.data.shippingAddress?.Address?.state}</p>
            <p><span className="font-semibold">City:</span>{order.data.shippingAddress?.Address?.city}</p>
            <p><span className="font-semibold">Postal Code:</span>{order.data.shippingAddress?.Address?.postalCode}</p>
            <p><span className="font-semibold">Email:</span> {order.data.shippingAddress?.Address?.email}</p>
            <p><span className="font-semibold">Drop-Off Instructions: </span>{order.data.shippingAddress?.DropOffInstructions}</p>
  
            {/* Prices */}
            <h2 className="font-semibold mt-4 text-xl">Prices:</h2>
            <p> <span className="font-semibold">Total Price:</span> €{order.data.totalPrice}</p>
            <p> <span className="font-semibold">Delivery Charges:</span> €{order.data.otherPrice?.deliveryCharges}</p>
            <p> <span className="font-semibold">Other Charges:</span> €{order.data.otherPrice?.otherCharges}</p>
  
            {/* Other Info */}
            <h2 className="font-semibold mt-4 text-xl">Other Info:</h2>
            <p><span className="font-semibold">Status:</span> {order.data.status}</p>
            <p><span className="font-semibold">Paid At:</span> {new Date(order.data.paidAt).toLocaleString()}</p>
            <p><span className="font-semibold">Created At:</span> {new Date(order.data.createdAt).toLocaleString()}</p>
            </div>
          </div>
        ) : (
          <p>No order details available.</p>
        )}
      </div>

      <div className="mb-20 flex items-center justify-center">
      <div className="flex md:flex-row flex-col md:w-[50vw] w-full items-center md:gap-10 gap-4 ">
      <div>
      <h1  className="text-3xl font-bold">Order Return</h1>
      </div>

      <div>
            <button
              className="bg-[#ACE03A] py-1 px-4 rounded-md"
              onClick={() => setIsModalOpen(true)} // Open modal
            >
              Return
            </button>
          </div>
      </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 relative w-full max-w-lg">
            <button
              className="absolute top-2 right-2 text-4xl font-bold"
              onClick={() => setIsModalOpen(false)} // Close modal
            >
              &times;
            </button>
            <OrderReturn orderId={id} setIsModalOpen={setIsModalOpen} />
            </div>
        </div>
      )}
    </>
  );
}

export default OrderDetails;
