import React, { useState } from "react";
import flagicon from "../../assets/images/flagicon.png";
import xclose from "../../assets/images/x-close.png";
import "../../index.css";
import { ENDPOINTS } from "../../api/apiRoutes";
import API from "../../api/apiService";

function EditCouponCode({ handleCloseModal, handleBannerSubmit }) {
  const [selectedCoupon, setSelectedCoupon] = useState({
    name: "",
    value: "",
    maxAmount: "",
    minAmount: ""
  });
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedCoupon({ ...selectedCoupon, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await API.post(ENDPOINTS.ADD_COUPONCODE, selectedCoupon, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        handleBannerSubmit(response.data);
        handleCloseModal();
      } else {
        console.error("Failed to create coupon:", response.data);
      }
    } catch (error) {
      console.error("Error creating coupon:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-gray-300 bg-opacity-50 backdrop-blur-sm z-40"></div>

      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="p-6 border-2 rounded-md w-auto bg-white">
          <div className="flex justify-between mb-4">
            <div className="p-2 border border-gray-300 rounded-md">
              <img src={flagicon} alt="Flag Icon" />
            </div>
            <div>
              <img
                src={xclose}
                alt="Close Icon"
                onClick={handleCloseModal}
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="mb-4">
            <p className="text-[#344054] MediumFont text-sm mb-4">Add Coupon Code</p>
            <label className="block text-sm text-[#344054] mb-2">Name:</label>
            <input
              type="text"
              name="name"
              value={selectedCoupon.name}
              placeholder="Enter Coupon Name"
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 w-full outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm text-[#344054] mb-2">Value:</label>
            <input
              type="text"
              name="value"
              value={selectedCoupon.value}
              placeholder="Enter Coupon Value"
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 w-full outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm text-[#344054] mb-2">Maximum Amount:</label>
            <input
              type="text"
              name="maxAmount"
              value={selectedCoupon.maxAmount}
              placeholder="Enter Maximum Amount"
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 w-full outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm text-[#344054] mb-2">Minimum Amount:</label>
            <input
              type="text"
              name="minAmount"
              value={selectedCoupon.minAmount}
              placeholder="Enter Minimum Amount"
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 w-full outline-none"
            />
          </div>

          <div className="flex gap-4 items-center justify-center mt-6">
            <button
              onClick={handleCloseModal}
              className="text-[#003E00] text-sm inter-Semi bg-[#F9FFF5] py-2 px-10 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className={`bg-[#003E00] text-sm inter-Semi text-white py-2 md:px-8 px-6 rounded-lg ${loading && "cursor-not-allowed"}`}
              disabled={loading}
            >
              {loading ? "Adding..." : "Add Coupon Code"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCouponCode;
