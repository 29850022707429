import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaHeart, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  addToCart,
  removeFromCart,
  clearItemFromCart,
  updateQuantity
} from "../../redux/slices/cartSlice";

const CartSlider = ({ isOpen, onClose }) => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [maxQuantityMessages, setMaxQuantityMessages] = useState({});

  if (!isOpen) return null;

  const totalAmount = cartItems.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  const handleCheckout = () => {
    navigate("/order-options", { state: { cartItems } });
  };

  // const handleIncrement = (productId) => {
  //   const existingItem = cartItems.find((item) => item._id === productId);
  //   if (existingItem) {
  //     dispatch(updateQuantity({ productId, quantity: existingItem.quantity + 1 }));
  //   }
  // };
  const handleIncrement = (productId) => {
    const existingItem = cartItems.find((item) => item._id === productId);
    if (existingItem) {
      if (existingItem.quantity < 100) {
        dispatch(updateQuantity({ productId, quantity: existingItem.quantity + 1 }));
        setMaxQuantityMessages((prevMessages) => ({
          ...prevMessages,
          [productId]: null, 
        }));
      } else {
        setMaxQuantityMessages((prevMessages) => ({
          ...prevMessages,
          [productId]: "Maximum quantity of 100 reached",
        }));
      }
    }
  };

  const handleDecrement = (productId) => {
    const existingItem = cartItems.find((item) => item._id === productId);
    if (existingItem && existingItem.quantity > 1) {
      dispatch(updateQuantity({ productId, quantity: existingItem.quantity - 1 }));
    }
  };

  // const handleAddToCart = (item) => {
  //   if (item.quantity < 100) { 
  //     dispatch(addToCart({ ...item, quantity: 1 }));
  //     setMaxQuantityMessages((prevMessages) => ({
  //       ...prevMessages,
  //       [item._id]: null, 
  //     }));
  //   } else {
  //     setMaxQuantityMessages((prevMessages) => ({
  //       ...prevMessages,
  //       [item._id]: `Maximum quantity of 100 reached`,
  //     }));
  //   }
  // };
  

  // const handleRemoveFromCart = (item) => {
  //   dispatch(removeFromCart(item));
  //   if (item.quantity <= 1) {
  //     setMaxQuantityMessages((prevMessages) => ({
  //       ...prevMessages,
  //       [item._id]: null, 
  //     }));
  //   }
  // };

  const handleClearItemFromCart = (item) => {
    dispatch(clearItemFromCart(item));
    setMaxQuantityMessages((prevMessages) => ({
      ...prevMessages,
      [item._id]: null, 
    }));
  };

  return (
    <>
      <div className="fixed top-0 right-0 w-full sm:w-[400px] bg-white shadow-lg z-50 p-2">
        <button
          onClick={onClose}
          className="absolute top-4 md:right-8 right-2  text-gray-600 hover:text-gray-800"
          aria-label="Close cart slider"
        >
          <IoMdClose size={24} />
        </button>

        <div className="h-[100vh] px-2 py-4 mt-4 overflow-y-auto">
          <h2 className="text-lg font-bold mb-4">Cart Items</h2>
          {cartItems.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <>
              <ul>
                {cartItems.map((item,product) => (
                  <li
                    key={item._id}
                    className="mb-4 flex items-center border-t p-2"
                  >
                  <Link  to={`/product/${item._id}`} >
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      className="w-28 h-28 object-cover mr-4 rounded-md"
                    />
                    </Link>
                    <div className="flex-1">
                      <p className="text-xl font-semibold">{item.name}</p>
                      <p className="text-sm mb-1 font-semibold">
                        Price: €{item.price ? item.price.toFixed(2) : "0.00"}
                      </p>
                      <p className="text-sm mb-1 font-semibold text-red-500">
                        Total: €{(item.price * item.quantity).toFixed(2)}
                      </p>

                      <div className="flex items-center mt-2">
                        <button
                          onClick={() => handleDecrement(item._id)}  // Corrected
                          className="text-gray-600 hover:text-red-600 p-2"
                        >
                          <AiOutlineMinus size={20} />
                        </button>

                        <span className="mx-2">{item.quantity}</span>

                        <button
                          onClick={() => handleIncrement(item._id)}
                          className="text-gray-600 hover:text-green-600 p-2"
                        >
                          <AiOutlinePlus size={20} />
                        </button>
                      </div>

                      {maxQuantityMessages[item._id] && (
                        <p className="text-sm text-red-500 mt-1">
                          {maxQuantityMessages[item._id]}
                        </p>
                      )}
                    </div>

                    <button
                      onClick={() => handleClearItemFromCart(item)}
                      className="text-gray-600 hover:text-red-600 p-2"
                      aria-label="Remove item from cart"
                    >
                      <FaTrash size={20} />
                    </button>
                  </li>
                ))}
              </ul>

              <div className="flex justify-between items-center mt-4 font-bold text-xl p-2 border border-gray-100 rounded-md">
                <span>Total Amount:</span>
                <span>EUR{totalAmount.toFixed(2)}</span>
              </div>
            </>
          )}

          {cartItems.length > 0 && (
            <div className="flex justify-center mt-2 mb-4">
              <button
                onClick={handleCheckout}
                className="bg-[#ACE03A] text-white py-2 px-4 rounded"
              >
                Go to Checkout
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CartSlider;

