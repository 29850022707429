import React, { useState } from "react";
import { ENDPOINTS } from "../../api/apiRoutes";
import API from "../../api/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrderReturn = ({ orderId, setIsModalOpen }) => {
  const [address, setAddress] = useState({message: "" });
  const [errors, setErrors] = useState({ message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    let isValid = true;

    if (!address.message.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        message: "Please provide a reason for returning the order.",
      }));
      isValid = false;
    }

    if (isValid) {
      try {
        setIsSubmitting(true);
        // API call using your specified method
        const res = await API.put(
          `${ENDPOINTS.RETURN_ORDER.replace(":id", orderId)}`,
          {
            message: address.message,
          }
        );
        toast.success("Return request submitted successfully!");
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error submitting return request:", error);
        toast.error("Failed to submit return request. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 border rounded-md shadow-md">
      <h1 className="text-2xl font-bold mb-6">Order Return</h1>
      <form onSubmit={handleSubmit}>
        {/* Message Input */}
        <div className="flex flex-col gap-1 mt-4">
          <label htmlFor="message">Reason for Return</label>
          <textarea
            id="message"
            placeholder="Please provide a reason for returning the order."
            className={`outline-none border p-2 rounded-md ${
              errors.message ? "border-red-500" : ""
            }`}
            value={address.message}
            onChange={(e) =>
              setAddress({ ...address, message: e.target.value })
            }
            onBlur={() => {
              if (!address.message.trim()) {
                setErrors((prevState) => ({
                  ...prevState,
                  message: "Please provide a reason for returning the order.",
                }));
              } else {
                setErrors((prevState) => ({
                  ...prevState,
                  message: "",
                }));
              }
            }}
            rows={4}
          />
          {errors.message && <p className="text-red-500">{errors.message}</p>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className={`mt-6 w-full bg-[#ACE03A] p-2 rounded-md transition ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Request"}
        </button>
      </form>
    </div>
  );
};

export default OrderReturn;
