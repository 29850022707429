import React, { useState, useEffect } from "react";
import "../../../index.css";
import search from "../../../assets/images/search-lg.png";
import AddCategoriesModal from "../../../components/AddCategoriesModal/AddCategoriesModal";
import EditCategoriesModal from "../../../components/EditCategoriesModal/EditCategoriesModal";
import AddFilterModal from "../../../components/AddFilterModal/AddFilterModal";
import API from "../../../api/apiService";
import { ENDPOINTS } from "../../../api/apiRoutes";
import ReactPaginate from "react-paginate";
import "./FoodCategories.css";
import { FaRegEdit } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from 'react-loader-spinner';

function FoodCategories() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await API.get(ENDPOINTS.GET_CATEGORIES_BYADMIN);
        setCategories(response.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
      finally {
        setLoading(false);  
      }
    };
    fetchCategories();
  }, []);

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleOpenEditModal = (category) => {
    setSelectedCategory(category);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedCategory(null);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const handleDeleteCategory = async (id) => {
  //   try {
  //     const response = await API.deleteResource(
  //       `${ENDPOINTS.DELETE_CATEGORY}/${id}`
  //     );
  //     if (response.status === 200) {
  //       setCategories((prevCategories) =>
  //         prevCategories.filter((category) => category._id !== id)
  //       );
  //       toast.success("Category deleted successfully");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting category:", error);
  //     toast.error("Error deleting category");
  //   }
  // };

  const handleDeleteCategory = async (id) => {
    try {
      await API.deleteResource(`${ENDPOINTS.DELETE_CATEGORY}/${id}`);

      setCategories((prevCategories) =>
        prevCategories.filter((category) => category._id !== id)
      );

      toast.success("Category deleted successfully");
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Error deleting category");
    }
  };

  const filteredData = categories.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      {isAddModalOpen && (
        <AddCategoriesModal handleCloseModal={handleCloseAddModal} />
      )}

      {isEditModalOpen && selectedCategory && (
        <EditCategoriesModal
          handleCloseModal={handleCloseEditModal}
          category={selectedCategory}
        />
      )}

      {isFilterModalOpen && (
        <AddFilterModal handleCloseModal={handleCloseFilterModal} />
      )}

      <div className="md:px-10 px-8 mt-10">
        <div className="flex justify-between items-center gap-4 my-4 flex-wrap">
          <div className="flex flex-col gap-2">
            <p className="text-[#020617] text-4xl BBoldFont">Categories</p>
            <p className="text-[#696969] text-base NormalFont">
              Here is a quick overview about your Categories
            </p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={handleOpenAddModal}
              className="bg-[#003E00] text-sm inter-Semi text-white p-[10px] rounded-md"
            >
              Add Categories
            </button>
          </div>
        </div>

        <div className="flex md:flex-row flex-col justify-between items-center gap-4 md:mt-10 mt-5">
          <div>
            <div className="border border-gray-300 flex p-3 rounded-lg gap-2 items-center">
              <img src={search} alt="" />
              <input
                type="text"
                placeholder="Search"
                className="md:w-[280px] outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>


        {loading ? (
          <div className="flex justify-center items-center my-10">
            <RotatingLines
              height="80"
              width="80"
              color="#003E00"
              ariaLabel="loading"
            />
          </div>
        ) : (

        <div className="my-10">
          <div className="overflow-x-auto">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #EAECF0",
                borderRadius: "20px",
              }}
            >
              <thead className="bg-gray-50 ">
                <tr className="border-b">

                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Category No.
                    </p>
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      padding: "12px 20px",
                    }}
                  >
                    <p className="text-[#475467] inter-Medium text-xs">
                      Category Name
                    </p>
                  </th>
                 
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Status
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}> </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr className="border-b" key={index}>
                  <td
                      style={{
                        padding: "16px 20px",
                      }}
                    >
                      <p className="text-[#101828] inter-Medium text-sm">
                      {offset + index + 1}
                      </p>
                    </td>
                    <td
                      style={{
                        padding: "16px 20px",
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <p className="text-[#101828] inter-Medium text-sm">
                        {item.name}
                      </p>
                    </td>
                    
                    <td style={{ padding: "16px 20px" }}>
                      <div
                        className={`rounded-xl w-[80px] flex justify-center ${
                          item.isActive ? "bg-[#ECFDF3]" : "bg-[#FFF5F5]"
                        }`}
                      >
                        <p
                          className={`inter-Medium p-2 rounded-xl text-xs ${
                            item.isActive ? "text-[#027A48]" : "text-[#D92D20]"
                          }`}
                        >
                          {item.isActive ? "Active" : "Inactive"}
                        </p>
                      </div>
                    </td>
                    <td style={{ padding: "16px 0px" }}>
                      <div className="flex gap-5">
                        <button>
                          <FaRegEdit
                            onClick={() => handleOpenEditModal(item)}
                            color="#003E00"
                            size={20}
                          />
                        </button>
                        <button onClick={() => handleDeleteCategory(item._id)}>
                          <MdDelete color="#003E00" size={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            breakClassName={"page-item"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
            
          />
        </div>
      )}
      </div>
    </>
  );
}

export default FoodCategories;
