// src/api/apiRoutes.js

export const ENDPOINTS = {
    REGISTER: 'auth/register',
    LOGIN: 'auth/login',
    VERIFICATION: 'auth/verify-otp',
    RESEND_OTP:'auth/send-otp',
    CREATE_CATEGORY: '/categories',
    UPDATE_CATEGORY:"categories/:id",
    DELETE_CATEGORY:"categories",
    GET_CATEGORIES:'/getallcategories',
    GET_CATEGORIES_BYADMIN:'/getallcategories_byadmin',
    GET_ALLPRODUCTS:'/inventory',
    GET_ALLPRODUCTS_BYADMIN:'/inventory_byadmin',
    CREATE_INVENTORIES:'/inventory',
    GET_INVENTORIES:'inventory/category',
    UPDATE_INVENTORIES:'/inventory',
    DELETE_INVENTORIES:'/inventory',
    CREATE_ORDER:'createOrder',
    UPDATE_ORDER:'update-order-Status',
    GET_ALLORDERS:'getallOrder',
    GET_ORDERBYID:'getOrderbyid',
    UPDATE_ORDER:'update-order-Status',
    ADD_SUBCATEGORIES:'/addsubCategory',
    GET_SUBCATEGORIES:"/getAllSubCategory",
    DELETE_SUBCATEGORIES:"/deleteSubCategory",
    UPDATE_SUBCATEGORIES:"/editSubcategory/:id",
    ADD_BANNER:'/banners',
    GET_ALLBANNER:'/banners',
    GET_BANNERBYTYPE:'/banners/type',
    UPDATE_BANNER:'/banners',
    DELETE_BANNER:'/banners',
    APPLYCOUPON_CODE:'/applyCouponCode',
    ADD_COUPONCODE:'/addCoupenCode',
    GET_ALLCOUPONCODE:'/getAllCoupenCode',
    DELETE_ALLCOUPONS:'/deleteCoupenCode',
    STRIPE_KEY:'stripeapikey',
    GET_USERORDER:'orders',
    FORGOT_PASSWORD:'auth/forgot-password',
    CONFIRM_OTP:'auth/confirm-otp',
    RESET_PASS:'auth/newPassword',
    RETURN_ORDER:'/order/:id/return',
    
  };

  