import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import login from "../../../../assets/images/login.png";
import { MdOutlineMailOutline } from "react-icons/md";
import OtpInput from "react-otp-input";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import API from "../../../../api/apiService";
import { ENDPOINTS } from "../../../../api/apiRoutes";
import "../../../../index.css";
import "./Verification.css";
import { useDispatch } from "react-redux";
import { verifyAdmin, verifyFailure } from "../../../../redux/slices/loginSlice";

function Verification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isResending, setIsResending] = useState(false);

  const handleVerifyOtp = async (values, { setSubmitting }) => {
    try {
      const userName = JSON.parse(localStorage.getItem("userName"));
      if (!userName) {
        toast.error("User name not found in local storage.");
        setSubmitting(false);
        return;
      }

      const response = await API.post(
        `${ENDPOINTS.VERIFICATION}/${userName}`,
        { code: values.otp }
      );

      if (response.success) {
        toast.success(response.message || "OTP verified successfully!");
        dispatch(verifyAdmin());
        navigate("/admin");
      } else {
        toast.error(response.message || "OTP verification failed.");
        dispatch(verifyFailure());
      }
    } catch (error) {
      toast.error(
        error.response?.message || "An error occurred while verifying the OTP."
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleSendOtp = async () => {
    setIsResending(true);
    try {
      const userName = JSON.parse(localStorage.getItem("userName"));
      const response = await API.post(`${ENDPOINTS.RESEND_OTP}`, {
        username: userName,
      });

      if (response) {
        toast.success(response.message || "OTP sent successfully!");
      } else {
        toast.error(response.message || "Failed to send OTP.");
      }
    } catch (error) {
      toast.error("OTP has already been sent! Please Verify first.");
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="md:flex justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00] flex items-end">
          <img src={login} alt="product" />
        </div>
        <div className="md:w-[50%] gap-5 rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div className="flex gap-4 items-center">
            <p className="text-2xl BoldFont">Check Your Email</p>
            <MdOutlineMailOutline size={28} />
          </div>

          <div>
            <p className="text-center NormalFont">
              We have sent a verification code to
            </p>
            <p className="text-center NormalFont">
              {localStorage.getItem("email")}
            </p>
          </div>

          <Formik
            initialValues={{ otp: "" }}
            onSubmit={handleVerifyOtp}
          >
            {({ isSubmitting }) => (
              <Form className="w-full flex flex-col items-center">
                <Field name="otp">
                  {({ field }) => (
                    <OtpInput
                      {...field}
                      value={field.value}
                      onChange={(otp) =>
                        field.onChange({
                          target: { name: field.name, value: otp },
                        })
                      }
                      numInputs={6}
                      renderInput={(props) => (
                        <input {...props} className="otp-input" />
                      )}
                    />
                  )}
                </Field>

                <div className="w-[50%]">
                  <button
                    type="submit"
                    className="w-full py-[12px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-6 inter-Semi"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Verifying..." : "Verify Email"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div>
            <p className="font-semibold text-center mt-8">
              Didn't receive the email?{" "}
              <button
                className="text-[#004D00]"
                onClick={handleSendOtp}
                disabled={isResending}
              >
                {isResending ? "Resending..." : "Click to resend"}
              </button>
            </p>
          </div>

          <div className="flex gap-4 justify-center mt-2 items-center">
            <IoMdArrowRoundBack
              size={24}
              color="green"
              onClick={() => navigate("/login")}
              className="cursor-pointer"
            />
            <button
              onClick={() => navigate("/login")}
              className="font-semibold text-center"
            >
              Back to Login
            </button>
          </div>

          <p className="text-gray-400 relative top-4 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    
    </div>
  );
}

export default Verification;
