import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import login from "../../../../assets/images/login.png";
import { MdOutlineMailOutline } from "react-icons/md";
import OtpInput from "react-otp-input";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import API from "../../../../api/apiService";
import { ENDPOINTS } from "../../../../api/apiRoutes";
import "../../../../index.css";
import "./forgotpassverify.css";


function ForgotpassVerify() {
  const navigate = useNavigate();
  const [isResending, setIsResending] = useState(false);
 

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const email = localStorage.getItem("email"); 
      const response = await API.post(ENDPOINTS.CONFIRM_OTP, {
        email,
        otp: values.otp,
      });

      if (response.message) {
        toast.success(response.message || "OTP verified successfully!");
        navigate("/newPassword"); 
      } else {
        toast.error("Failed to verify OTP.");
      }
    } catch (error) {
      toast.error(
        error.response?.message || "An error occurred while verifying OTP."
      );
    } finally {
      setSubmitting(false);
    }
  };



  const handleResend = async () => {
    setIsResending(true);
    try {
      const email = localStorage.getItem("email"); 
      const userName = JSON.parse(localStorage.getItem("userName"));
      const response = await API.post(ENDPOINTS.RESEND_OTP, { email,username:userName });

      if (response.message) {
        toast.success(response.message || "OTP resent successfully!");
      } else {
        toast.error("Failed to resend OTP.");
      }
    } catch (error) {
      toast.error(
        error.response?.message || "An error occurred while resending OTP."
      );
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="md:flex  justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00]  flex items-end">
          <img src={login} alt="product" className="" />
        </div>
        <div className="md:w-[50%] gap-5 rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div className="flex gap-4 items-center">
            <p className="text-2xl BoldFont">Check Your Email</p>
            <MdOutlineMailOutline size={28} />
          </div>

          <div>
            <p className="text-center NormalFont">
              We have sent a verification code to
            </p>
            <p className="text-center NormalFont">
              {localStorage.getItem("email")}
            </p>
          </div>

          <Formik initialValues={{ otp: "" }}   onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form className="w-full flex flex-col items-center ">
                <Field name="otp">
                  {({ field }) => (
                    <OtpInput
                      {...field}
                      value={field.value}
                      onChange={(otp) =>
                        field.onChange({
                          target: { name: field.name, value: otp },
                        })
                      }
                      numInputs={6}
                      renderInput={(props) => (
                        <input {...props} className="otp-input" />
                      )}
                    />
                  )}
                </Field>

                <div className="w-[50%]">
                  <button
                    type="submit"
                    className="w-full py-[12px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-6 inter-Semi"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Verifying..." : "Verify Email"}
                  </button>
                </div>

                <div>
                  <p className="font-semibold text-center mt-8">
                    Didn't receive the email?{" "}
                    <button
                      type="button"
                      onClick={handleResend}
                      className="text-[#004D00]"
                      disabled={isResending}
                    >
                      {isResending ? "Resending..." : "Click to resend"}
                    </button>
                  </p>
                </div>

                <div className="flex gap-4 justify-center mt-2 items-center">
                  <IoMdArrowRoundBack
                    size={24}
                    color="green"
                    onClick={() => navigate("/login")}
                    className="cursor-pointer"
                  />
                  <button
                    onClick={() => navigate("/login")}
                    className="font-semibold text-center"
                  >
                    Back to Login
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <p className="text-gray-400 relative top-4 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotpassVerify;
