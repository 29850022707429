import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";

function Return_OrderDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const res = await API.get(`${ENDPOINTS.GET_ORDERBYID}/${id}`);
        setOrder(res);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to fetch order details.");
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  return (
    <div className="min-h-screen bg-white  py-8 px-4">
      <div className="max-w-4xl mx-auto bg-gray-50 rounded-lg shadow-lg p-6">
        {loading ? (
          <div className="flex justify-center items-center h-96">
            <p className="text-lg font-semibold text-gray-600">Loading...</p>
          </div>
        ) : error ? (
          <div className="flex justify-center items-center h-96">
            <p className="text-lg font-semibold text-red-500">{error}</p>
          </div>
        ) : order ? (
          <>
            <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              Return Order Details
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Payment Info */}
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Payment Info
                </h2>
                <p className="text-gray-600 mb-2">
                  <span className="font-semibold">Type:</span> {order.data.paymentInfo?.type}
                </p>

                {/* Cart Items */}
                <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-4">
                  Cart Items
                </h2>
                <ul className="space-y-4">
                  {(order?.data.cart || []).map((item) => (
                    <li key={item._id} className="flex items-start gap-4">
                      <img
                        src={item.imageUrl}
                        alt={item.name}
                        className="w-20 h-20 rounded border"
                      />
                      <div>
                        <p className="font-semibold text-gray-700">{item.name}</p>
                        <p className="text-gray-600">
                          Quantity: {item.quantity}
                        </p>
                        <p className="text-gray-600">Price: €{item.price}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Shipping Info */}
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Shipping Address
                </h2>
                {Object.entries(order.data.shippingAddress?.Address || {}).map(
                  ([key, value]) => (
                    <p className="text-gray-600 mb-2" key={key}>
                      <span className="font-semibold capitalize">{key}:</span> {value}
                    </p>
                  )
                )}
                <p className="text-gray-600 mb-2">
                  <span className="font-semibold">Drop-Off Instructions:</span>{" "}
                  {order.data.shippingAddress?.DropOffInstructions}
                </p>

                {/* Prices */}
                <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-4">
                  Prices
                </h2>
                <p className="text-gray-600 mb-2">
                  <span className="font-semibold">Total Price:</span> €{order.data.totalPrice}
                </p>
                <p className="text-gray-600 mb-2">
                  <span className="font-semibold">Delivery Charges:</span> €{order.data.otherPrice?.deliveryCharges}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">Other Charges:</span> €{order.data.otherPrice?.otherCharges}
                </p>
              </div>
            </div>

            {/* Other Info */}
            <div className="mt-8">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Other Info
              </h2>
              <p className="text-gray-600 mb-2">
                <span className="font-semibold">Status:</span> {order.data.status}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-semibold">Paid At:</span>{" "}
                {new Date(order.data.paidAt).toLocaleString()}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-semibold">Created At:</span>{" "}
                {new Date(order.data.createdAt).toLocaleString()}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-semibold">Message:</span> {order.data.returnMessage}
              </p>
              <p className="text-gray-600">
                <span className="font-semibold">Return Date:</span>{" "}
                {new Date(order.data.returnDate).toLocaleString()}
              </p>
            </div>
          </>
        ) : (
          <p className="text-center text-gray-600">No order details available.</p>
        )}
      </div>
    </div>
  );
}

export default Return_OrderDetails;
