import React, { useEffect, useState } from "react";
import "../../../index.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import API, { ENDPOINTS } from "../../../api/apiService";
import { toast, ToastContainer } from "react-toastify";
import { fetchOrders } from "../../../redux/slices/orderSlice";

function OrderDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Set initial order status to "Pending"
  const [orderstatus, setOrderStatus] = useState("Pending");
  const { orders } = useSelector((state) => state.orders);
  // const data = orders && orders.find((item) => item._id === id);

  // const orderUpdateHandler = async () => {
  //   const res = await API.put(
  //     `${ENDPOINTS.UPDATE_ORDER}/${id}`,
  //     {
  //       orderstatus,
  //     },
  //     { withCredentials: true }
  //   );
  //   if (res.success) {
  //     toast.success("Order Updated!");
  //     navigate("/admin");
  //   }
  // };
  //  const orderUpdateHandler = async () => {
  //   if (data?.orderstatus === "Delivered") {
  //     // Show error if already delivered
  //     toast.error("Order has already been delivered!");
  //     return;
  //   }

  //   const res = await API.put(
  //     `${ENDPOINTS.UPDATE_ORDER}/${id}`,
  //     {
  //       orderstatus,
  //     },
  //     { withCredentials: true }
  //   );

  //   if (res.success) {
  //     toast.success("Order Updated!");
  //     // navigate("/admin");
  //   } else {
  //     toast.error("The order has already been delivered!");
  //   }
  // };
  useEffect(() => {
    dispatch(fetchOrders()); 
  }, [dispatch, orders, id]);

  const data = orders && orders.find((item) => item._id === id);

  const orderUpdateHandler = async () => {
    if (data?.orderstatus === "Delivered") {
      toast.error("Order has already been delivered!");
      return;
    }

    const res = await API.put(
      `${ENDPOINTS.UPDATE_ORDER}/${id}`,
      {
        orderstatus,
      },
      { withCredentials: true }
    );

    if (res.success) {
      toast.success("Order Updated!");
      dispatch(fetchOrders()); 
    } else {
      toast.error("The order has already been delivered!");
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="main p-8">
        <div className="flex md:flex-row flex-wrap justify-between gap-4 items-center">
          <div className="flex flex-col gap-2">
            <h1 className="text-[#020617] md:text-4xl text-2xl BBoldFont">
              Order Id #{data?._id?.slice(0, 8)}
            </h1>
            <p className="text-[#696969] text-base NormalFont">
              Here is a quick overview about your restaurant's Menu
            </p>
          </div>
        </div>

        <div className="mt-10 flex w-full ">
          <div className="flex flex-col gap-10 w-[80vw]  ">
            <div className="flex flex-col gap-5">
              <div>
                <h1 className="text-[#475467] text-xl BBoldFont">
                  Customer Information
                </h1>
              </div>

              <div className="flex ">
                <div className="flex flex-col gap-6 w-[50%] ">
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">Name</h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.shippingAddress?.Address.fullName}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">
                      Shipping Address
                    </h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.shippingAddress?.Address.street},{" "}
                      {data?.shippingAddress?.Address.city},{" "}
                      {data?.shippingAddress?.Address.state},{" "}
                      {data?.shippingAddress?.Address.street}
                    </p>
                    <div className="flex flex-col gap-2 ">
                      <h1 className="text-[#344054] MediumFont text-sm ">
                        Phone number
                      </h1>
                      <p className="text-[#667085] NormalFont text-base">
                        {data?.shippingAddress?.Address.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 w-[50%] ">
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">
                      Order Status
                    </h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.status}
                    </p>
                    {data?.status !== "Processing refund" &&
                      data?.status !== "Refund Success" && (
                        <select
                          value={orderstatus}
                          onChange={(e) => setOrderStatus(e.target.value)}
                          className="w-[200px] mt-2 border h-[35px] rounded-[5px] outline-none"
                        >
                          {[
                            "Pending",
                            "Confirmed",
                            "Delivered",
                            "Received",
                          ].map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                    <div
                      className={`w-[150px] h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer mt-5 !bg-[#FCE1E6]  text-[#E94560] font-[600]  text-[18px]`}
                      onClick={
                        data?.status !== "Processing refund"
                          ? orderUpdateHandler
                          : null
                      }
                    >
                      Update Status
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <h1 className="text-[#475467] text-xl BBoldFont">
                  Product Details
                </h1>
              </div>

              {data &&
                data?.cart.map((item, index) => (
                  <div className="w-full flex items-start mb-5" key={index}>
                    <img
                      src={`${item?.imageUrl}`}
                      alt=""
                      className="w-[100px] h-[100px]"
                    />
                    <div className="w-full">
                      <h5 className="pl-3 text-[20px] font-semibold">
                        {item.name}
                      </h5>
                      <h5 className="pl-3 text-[20px] text-[#00000091]">
                        Total: €{item.price} x {item.quantity}
                      </h5>
                    </div>
                  </div>
                ))}

              <div className="border-b mr-2 w-full text-center">
                <h5 className="pt-3 text-[18px]">
                  Total Price: <strong>€{data?.totalPrice}</strong>
                </h5>
              </div>
            </div>

            <div className="flex flex-col gap-5">
              <div>
                <h1 className="text-[#475467] text-xl BBoldFont">
                  Payment Information
                </h1>
              </div>

              <div className="flex ">
                <div className="flex flex-col gap-6 w-[50%] ">
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">
                      Payment Method{" "}
                    </h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.paymentInfo.type}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">
                      Payment Date
                    </h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.paidAt?.slice(0, 10)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-6 w-[50%] ">
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">
                      Transaction ID
                    </h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.paymentInfo.id ? data.paymentInfo.id : "Not Paid"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <h1 className="text-[#344054] MediumFont text-sm ">
                      Total
                    </h1>
                    <p className="text-[#667085] NormalFont text-base">
                      {data?.totalPrice}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetail;
