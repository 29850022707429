import React, { useState, useEffect } from "react";
import API from "../../../api/apiService";
import { ENDPOINTS } from "../../../api/apiRoutes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../index.css";
import search from "../../../assets/images/search-lg.png";
import AddBannerModal from "../../../components/AddBannerModal/AddBannerModal";
import EditBannerModal from "../../../components/EditBannerModal/EditBannerModal";
import "./Banner.css";
import ReactPaginate from "react-paginate";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RotatingLines } from 'react-loader-spinner';

function Banners() {
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(false);

  const handleOpenBannerModal = () => {
    setIsBannerModalOpen(true);
  };

  const handleCloseBannerModal = () => {
    setIsBannerModalOpen(false);
  };
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredItems.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleBannerSubmit = (bannerData) => {
    setData((prevData) => [...prevData, bannerData]);
    handleCloseBannerModal();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        setLoading(true); 
        const token = localStorage.getItem("token");
        const response = await API.get(ENDPOINTS.GET_ALLBANNER, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.banners);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching banners:", error);
        setLoading(false);  
      }
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    const filteredData = data.filter(
      (item) =>
        item.bannerType &&
        item.bannerType.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filteredData);
  }, [searchTerm, data]);

  const deleteBanner = async (bannerId) => {
    try {
      const token = localStorage.getItem("token");
      await API.deleteResource(`${ENDPOINTS.DELETE_BANNER}/${bannerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data.filter((item) => item._id !== bannerId));

      toast.success("Banner deleted successfully!", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error deleting banner:", error);
      toast.error("Failed to delete the banner.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const openEditModal = (banner) => {
    setSelectedBanner(banner);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedBanner(null);
  };

  const handleUpdateBanner = (updatedBanner) => {
    setData((prevData) =>
      prevData.map((item) =>
        item._id === updatedBanner._id ? updatedBanner : item
      )
    );
    closeEditModal();
  };
  return (
    <>
      <ToastContainer />
      {isBannerModalOpen && (
        <AddBannerModal
          handleCloseModal={handleCloseBannerModal}
          handleBannerSubmit={handleBannerSubmit}
        />
      )}

      <div className="md:px-10 px-8 py-6">
        <div className="flex justify-between items-center gap-4 my-4">
          <div className="flex flex-col gap-2">
            <p className="text-[#020617] text-4xl BBoldFont">Banners</p>
            <p className="text-[#696969] text-base NormalFont">
              Here is a quick overview about your Banner
            </p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={handleOpenBannerModal}
              className="bg-[#003E00] text-sm inter-Semi text-white p-[10px] rounded-md"
            >
              Add Banner
            </button>
          </div>
        </div>

        <div className="flex md:flex-row flex-col justify-between items-center gap-4 mt-10">
          <div>
            <div className="border border-gray-300 flex p-3 rounded-lg gap-2 items-center">
              <img src={search} alt="search" />
              <input
                type="text"
                placeholder="Search"
                className="md:w-[280px] outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>

        {loading ? (
        <div className="flex justify-center items-center">
        <RotatingLines
              height="80"
              width="80"
              color="#003E00"
              ariaLabel="loading"
            />
        </div>
      ) : (

        <div className="my-10">
          <div className="overflow-x-auto">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #EAECF0",
                borderRadius: "20px",
              }}
            >
              <thead className="bg-gray-50 ">
                <tr className="border-b">
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Banner Type
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Start Date
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      End Date
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs">
                      Status
                    </p>
                  </th>
                  <th style={{ textAlign: "left", padding: "12px 20px" }}>
                    <p className="text-[#475467] inter-Medium text-xs"></p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr className="border-b" key={index}>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.bannerType}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.startDate}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <p className="text-[#475467] inter-Regular text-sm">
                        {item.endDate}
                      </p>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <div className="bg-[#ECFDF3] rounded-xl w-[80px] flex justify-center">
                        <p className="inter-Medium bg-[#ECFDF3] p-2 rounded-xl text-xs text-[#027A48]">
                          {item.isActive ? "Active" : "Inactive"}
                        </p>
                      </div>
                    </td>
                    <td style={{ padding: "16px 20px" }}>
                      <div className="flex gap-5">
                        <button onClick={() => openEditModal(item)}>
                          <FaRegEdit color="#003E00" size={20} />
                        </button>
                        <button onClick={() => deleteBanner(item._id)}>
                          <MdDelete color="#003E00" size={20} />
                        </button>
                        {isEditModalOpen && selectedBanner && (
                          <EditBannerModal
                            handleCloseModal={closeEditModal}
                            bannerId={selectedBanner._id}
                            token={localStorage.getItem("token")}
                            bannerData={selectedBanner}
                            onUpdateBanner={handleUpdateBanner}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            breakClassName={"page-item"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
          />
        </div>
      )}
      </div>
    </>
  );
}

export default Banners;
