// Try to add the quantity upto 100 items
import { createSlice } from "@reduxjs/toolkit";

const loadCartFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("cart");
    return serializedState ? JSON.parse(serializedState) : [];
  } catch (e) {
    console.error("Could not load cart from localStorage", e);
    return [];
  }
};

const saveCartToLocalStorage = (cartItems) => {
  try {
    const serializedState = JSON.stringify(cartItems);
    localStorage.setItem("cart", serializedState);
  } catch (e) {
    console.error("Could not save cart to localStorage", e);
  }
};

const initialState = {
  cartItems: loadCartFromLocalStorage(),
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.cartItems.find(
        (cartItem) => cartItem._id === item._id
      );

      if (existingItem) {
        const newQuantity = existingItem.quantity + item.quantity;
        if (newQuantity <= 100) { // Cap at 100
          existingItem.quantity = newQuantity;
        } else {
          existingItem.quantity = 100; // Set to max if exceeded
        }
      } else {
        state.cartItems.push({
          ...item,
          quantity: item.quantity || 1,
          stock: item.stock,
        });
      }

      saveCartToLocalStorage(state.cartItems);
    },
    removeFromCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.cartItems.find(
        (cartItem) => cartItem._id === item._id
      );
      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.cartItems = state.cartItems.filter(
            (cartItem) => cartItem._id !== item._id
          );
        }
      }
      saveCartToLocalStorage(state.cartItems);
    },

    clearItemFromCart: (state, action) => {
      const item = action.payload;
      state.cartItems = state.cartItems.filter(
        (cartItem) => cartItem._id !== item._id
      );
      saveCartToLocalStorage(state.cartItems);
    },

    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      const existingItem = state.cartItems.find((item) => item._id === productId);
      if (existingItem) {
        existingItem.quantity = quantity;
      }
      saveCartToLocalStorage(state.cartItems);
    },

    clearCart: (state) => {
      state.cartItems = [];
      saveCartToLocalStorage(state.cartItems);
    },
  },
});

export const { addToCart, removeFromCart, clearItemFromCart, clearCart,updateQuantity  } =
  cartSlice.actions;
export default cartSlice.reducer;
