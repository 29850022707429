import React from "react";
import logo from "../../assets/images/apnadookan.png";
import img1 from "../../assets/images/refundable.png";
import img2 from "../../assets/images/freedeliver.png";
import img3 from "../../assets/images/Payment.png";
import img4 from "../../assets/images/Gfb.png";
import img5 from "../../assets/images/Ginsta.png";
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { TbClockHour9 } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer({ onWishlistClick }) {
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const handleCheckout = () => {
    navigate("/order-options", { state: { cartItems } });
  };
  return (
    <>
      <div className="p-8 flex flex-col bg-gray-100 ">
        <div className="flex flex-row flex-wrap md:gap-12 gap-6 items-center justify-center">
          <div>
            <img src={logo} className="h-[120px]" />
          </div>
          <div className="flex gap-4 md:w-[20vw]">
            <div>
              <img src={img1} />
            </div>
            <div className="flex flex-col">
              <h1 className="text-[#253D4E] font-semibold text-lg">
                Best Prices & Deals
              </h1>
              <p className="text-[#253D4E] font-base text-[16px]">
                Don’t miss our daily amazing deals and prices
              </p>
            </div>
          </div>
          <div className="flex gap-4 md:w-[20vw]">
            <div>
              <img src={img1} />
            </div>
            <div className="flex  flex-col">
              <h1 className="text-[#253D4E] font-semibold text-lg">
                Refundable{" "}
              </h1>
              <p className="text-[#253D4E] font-base text-[16px]">
                If your items have damage we agree to refund it
              </p>
            </div>
          </div>
          <div className="flex gap-4 md:w-[20vw]">
            <div>
              <img src={img1} />
            </div>
            <div className="flex  flex-col">
              <h1 className="text-[#253D4E] font-semibold text-lg">
                Free delivery{" "}
              </h1>
              <p className="text-[#253D4E] font-base text-[16px]">
                Do purchase over €50 and get free delivery anywhere
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-wrap gap-6 justify-between border-b border-b-gray-300 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex gap-2">
              <IoLocationOutline color="#ACE03A" size={24} />
              <p className="text-[#253D4E] font-semibold text-base">
                Address:
                <span className="text-[#253D4E] font-medium text-base">
                  {" "}
                  1762 School House Road{" "}
                </span>
              </p>
            </div>
            <div className="flex gap-2">
              <IoCallOutline color="#ACE03A" size={24} />
              <p className="text-[#253D4E] font-semibold text-base">
                Call Us:
                <span className="text-[#253D4E] font-medium text-base">
                  {" "}
                  1233-777{" "}
                </span>
              </p>
            </div>
            <div className="flex gap-2">
              <MdOutlineMail color="#ACE03A" size={24} />
              <p className="text-[#253D4E] font-semibold text-base">
                Email:
                <span className="text-[#253D4E] font-medium text-base">
                  {" "}
                  contact@apnadookan.fi{" "}
                </span>
              </p>
            </div>
            <div className="flex gap-2">
              <TbClockHour9 color="#ACE03A" size={24} />
              <p className="text-[#253D4E] font-semibold text-base">
                Work hours:
                <span className="text-[#253D4E] font-medium text-base">
                  {" "}
                  8:00 - 20:00, Sunday - Thursday{" "}
                </span>
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <div>
              <p className="text-[#253D4E] font-semibold text-2xl">Account</p>
            </div>

            <div className="flex flex-col gap-2">
              <button
                onClick={onWishlistClick}
                className="text-[#253D4E] font-medium text-base text-left"
              >
                Wishlist
              </button>

              <Link
                to="/profile"
                className="text-[#253D4E] font-medium text-base"
              >
                Profile
              </Link>
              <button
                onClick={handleCheckout}
                className="text-[#253D4E] font-medium text-base "
              >
                Shipping Details
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <p className="text-[#253D4E] font-semibold text-2xl">Links</p>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <img src={img5} />
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  className="text-[#253D4E] font-medium text-base"
                >
                  Instagram
                </a>
              </div>
              <div className="flex gap-2 items-center">
                <img src={img4} />
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  className="text-[#253D4E] font-medium text-base"
                >
                  Facebook
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <p className="text-[#253D4E] font-semibold text-2xl">
                Help Center
              </p>
            </div>

            <div className="flex flex-col gap-2">
              <Link
                to="/payment"
                className="text-[#253D4E] font-medium text-base"
              >
                Payments
              </Link>
              <Link
                to="/refund"
                className="text-[#253D4E] font-medium text-base"
              >
                Refund
              </Link>
              {/* <button
                onClick={handleCheckout}
                className="text-[#253D4E] font-medium text-base w-[70px]"
              >
                Checkout
              </button> */}

              <Link to="/QA" className="text-[#253D4E] font-medium text-base">
                Q&A
              </Link>
              <Link
                to="/privacyPolicy"
                className="text-[#253D4E] font-medium text-base"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center pt-6">
          <img src={img3} />
        </div>
      </div>
    </>
  );
}

export default Footer;
