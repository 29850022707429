// import React, { useEffect, useState } from "react";
// import { Mail, Phone, MapPin } from "lucide-react";
// import Navbar from "../../components/Navbar/Navbar";
// import Footer from "../../components/Footer/Footer";
// import {toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

// const Contact = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const [formData, setFormData] = useState({
//     firstname: "",
//     lastname: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setFormData({ firstname: "", lastname: "", email: "", message: "" });

//     // Display success message
//     toast.success("Form submitted successfully!");
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="min-h-screen">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//           <div className="bg-white shadow-lg rounded-lg overflow-hidden">
//             <div className="px-6 py-8">
//               <h2 className="text-3xl font-bold text-gray-900 mb-4">
//                 Contact Us
//               </h2>
//               <p className="text-gray-600 mb-8">
//                 We'd love to hear from you! Please fill out the form below or
//                 use our contact information.
//               </p>

//               <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//                 <div>
//                   <form onSubmit={handleSubmit} className="space-y-6">
//                     <div className="flex flex-col md:flex-row md:gap-2 gap-5 w-full">
//                       <div>
//                         <label
//                           htmlFor="firstname"
//                           className="block text-sm font-medium text-gray-700"
//                         >
//                           First Name
//                         </label>
//                         <input
//                           type="text"
//                           id="firstname"
//                           name="firstname"
//                           value={formData.firstname}
//                           onChange={handleChange}
//                           required
//                           className="mt-1 block w-full xl:w-[280px] border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
//                         />
//                       </div>
//                       <div>
//                         <label
//                           htmlFor="lastname"
//                           className="block text-sm font-medium text-gray-700"
//                         >
//                           Last Name
//                         </label>
//                         <input
//                           type="text"
//                           id="lastname"
//                           name="lastname"
//                           value={formData.lastname}
//                           onChange={handleChange}
//                           required
//                           className="mt-1 block w-full xl:w-[280px] border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
//                         />
//                       </div>
//                     </div>
//                     <div>
//                       <label
//                         htmlFor="email"
//                         className="block text-sm font-medium text-gray-700"
//                       >
//                         Email
//                       </label>
//                       <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         required
//                         className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
//                       />
//                     </div>
//                     <div>
//                       <label
//                         htmlFor="message"
//                         className="block text-sm font-medium text-gray-700"
//                       >
//                         Message
//                       </label>
//                       <textarea
//                         id="message"
//                         name="message"
//                         rows="4"
//                         value={formData.message}
//                         onChange={handleChange}
//                         required
//                         className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
//                       ></textarea>
//                     </div>
//                     <div>
//                       <button
//                         type="submit"
//                         className="bg-[#ACE03A] text-white py-2 px-4 rounded-xl"
//                       >
//                         SUBMIT
//                       </button>
//                     </div>
//                   </form>
//                 </div>

//                 <div className="space-y-6">
//                   <div>
//                     <h3 className="text-lg font-medium text-gray-900 mb-2">
//                       Contact Information
//                     </h3>
//                     <div className="space-y-3">
//                       <p className="flex items-center text-gray-600">
//                         <Mail className="mr-2 h-5 w-5 text-[#ACE03A]" />
//                         info@eurofreshmart.com
//                       </p>
//                       <p className="flex items-center text-gray-600">
//                         <Phone className="mr-2 h-5 w-5 text-[#ACE03A]" />
//                         +00000000000
//                       </p>
//                       <p className="flex items-center text-gray-600">
//                         <MapPin className="mr-2 h-5 w-5 text-[#ACE03A]" />
//                         123 Fence Street, Sydney, NSW 2000
//                       </p>
//                     </div>
//                   </div>
//                   <div>
//                     <h3 className="text-lg font-medium text-gray-900 mb-2">
//                       Our Location
//                     </h3>
//                     <div className="aspect-w-16 aspect-h-9">
//                       <iframe
//                         title="Aus Fence Hire Location"
//                         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26437.750312939866!2d151.2040164!3d-33.8688197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae401e8b983f%3A0x5017d681632ccc0!2sSydney%20NSW%2C%20Australia!5e0!3m2!1sen!2sus!4v1631234567890!5m2!1sen!2sus"
//                         width="100%"
//                         height="100%"
//                         style={{ border: 0 }}
//                         allowFullScreen=""
//                         loading="lazy"
//                       ></iframe>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />

//     </>
//   );
// };

// export default Contact;



import React, { useEffect, useState } from "react";
import { Mail, Phone, MapPin } from "lucide-react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email via EmailJS
    emailjs
      .send(
        "service_t9bhbtl", // Replace with your EmailJS Service ID
        "template_tbiyfyk", // Replace with your EmailJS Template ID
        {
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          message: formData.message,
        },
        "SJkVTbm902hUCYo-G" // Replace with your EmailJS Public Key
      )
      .then(
        () => {
          toast.success("Email sent successfully!");
          setFormData({ firstname: "", lastname: "", email: "", message: "" });
        },
        (error) => {
          toast.error("Failed to send email. Please try again.");
          console.error("EmailJS Error:", error);
        }
      );
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Contact Us
              </h2>
              <p className="text-gray-600 mb-8">
                We'd love to hear from you! Please fill out the form below or
                use our contact information.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex flex-col md:flex-row md:gap-2 gap-5 w-full">
                      <div>
                        <label
                          htmlFor="firstname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          name="firstname"
                          value={formData.firstname}
                          onChange={handleChange}
                          required
                          className="mt-1 block w-full xl:w-[280px] border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="lastname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          name="lastname"
                          value={formData.lastname}
                          onChange={handleChange}
                          required
                          className="mt-1 block w-full xl:w-[280px] border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      ></textarea>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="bg-[#ACE03A] text-white py-2 px-4 rounded-xl"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>

                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      Contact Information
                    </h3>
                    <div className="space-y-3">
                      <p className="flex items-center text-gray-600">
                        <Mail className="mr-2 h-5 w-5 text-[#ACE03A]" />
                        contact@apnadookan.fi
                      </p>
                      <p className="flex items-center text-gray-600">
                        <Phone className="mr-2 h-5 w-5 text-[#ACE03A]" />
                        +00000000000
                      </p>
                      <p className="flex items-center text-gray-600">
                        <MapPin className="mr-2 h-5 w-5 text-[#ACE03A]" />
                        123 Fence Street, Sydney, NSW 2000
                      </p>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      Our Location
                    </h3>
                    <div className="aspect-w-16 aspect-h-9">
                      <iframe
                        title="Aus Fence Hire Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26437.750312939866!2d151.2040164!3d-33.8688197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae401e8b983f%3A0x5017d681632ccc0!2sSydney%20NSW%2C%20Australia!5e0!3m2!1sen!2sus!4v1631234567890!5m2!1sen!2sus"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
