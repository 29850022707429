import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Protected = ({ Component }) => {
  const { isLoggedIn, Admin } = useSelector((state) => state.login);

// if (!isLoggedIn) {
//   return <div>Please log in to access this page.</div>;
// }

  if (!isLoggedIn || !Admin) {
   
    return <Navigate to="/login" />;
  }

  return <Component />;
};

export default Protected;