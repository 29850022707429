import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "../../redux/slices/orderSlice";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import search from "../../assets/images/search-lg.png";
import arrowdown from "../../assets/images/arrowdowngray.png";
import ReactPaginate from "react-paginate";
import "./ReturnRecentOrders.css";
import { RotatingLines } from "react-loader-spinner";
import { FaDownload } from "react-icons/fa6";
import { jsPDF } from "jspdf";
import logo from "../../assets/images/apnadookan.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ReturnRecentOrders() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null); // From Date
  const [endDate, setEndDate] = useState(null); // To Date
  const [filteredallOrders, setFilteredallOrders] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const dispatch = useDispatch();
  const { orders, status, loading } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  //   useEffect(() => {
  //     setFilteredallOrders(orders);
  //   }, [orders]);
  useEffect(() => {
    // Filter orders with "return" status after fetching
    const returnedOrders = orders.filter(
      (orders) => orders.status === "Returned"
    );
    setFilteredallOrders(returnedOrders);
  }, [orders]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

 
  // const handleApplyFilter = () => {
  //   // Filter orders with "Returned" status first
  //   const returnedOrders = orders.filter(
  //     (order) => order.status === "Returned"
  //   );

  //   // Apply the date filter to the filtered returned orders
  //   const filtered = returnedOrders.filter((order) => {
  //     const orderDate = new Date(order.createdAt);
  //     if (startDate && endDate) {
  //       return orderDate >= startDate && orderDate <= endDate;
  //     } else if (startDate) {
  //       return orderDate >= startDate;
  //     } else if (endDate) {
  //       return orderDate <= endDate;
  //     }
  //     return true; // If no date filters are applied, include the order
  //   });

  //   setFilteredallOrders(filtered); // Update filtered orders
  // };
  const handleApplyFilter = () => {
    // Remove time from startDate and endDate for comparison purposes
    const startDateNoTime = startDate ? new Date(startDate.setHours(0, 0, 0, 0)) : null;
    const endDateNoTime = endDate ? new Date(endDate.setHours(23, 59, 59, 999)) : null;
  
    // Filter orders with "Returned" status first
    const returnedOrders = orders.filter(
      (order) => order.status === "Returned"
    );
  
    // Apply the date filter to the filtered returned orders
    const filtered = returnedOrders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      const orderDateNoTime = new Date(orderDate.setHours(0, 0, 0, 0)); // Set time to 00:00:00 for comparison
  
      if (startDateNoTime && endDateNoTime) {
        return orderDateNoTime >= startDateNoTime && orderDateNoTime <= endDateNoTime;
      } else if (startDateNoTime) {
        return orderDateNoTime >= startDateNoTime;
      } else if (endDateNoTime) {
        return orderDateNoTime <= endDateNoTime;
      }
      return true; // If no date filters are applied, include the order
    });
  
    setFilteredallOrders(filtered); // Update filtered orders
  
    // Show a toast notification if orders are found
    if (filtered.length > 0) {
      const formattedStartDate = startDateNoTime ? startDateNoTime.toLocaleDateString() : 'any';
      const formattedEndDate = endDateNoTime ? endDateNoTime.toLocaleDateString() : 'any';
      toast.success(`Orders found for ${formattedStartDate} to ${formattedEndDate}`);
    } else {
      toast.info('No orders found for the selected date range.');
    }
  };
  

  const handleDownloadAllInvoice = async () => {
    setIsDownloading(true); // Set downloading state to true when starting the download

    const ordersToDownload =
      filteredallOrders.length > 0 ? filteredallOrders : orders;

    for (const order of ordersToDownload) {
      await new Promise((resolve) => {
        generateInvoiceAll(order, resolve); // Generate invoice and save it directly
      });
    }

    setIsDownloading(false); // Reset the downloading state after all downloads are done
  };

  const generateInvoiceAll = (order, callback) => {
    const doc = new jsPDF();
  
    const img = new Image();
    img.src = logo;
  
    img.onload = () => {
      const logoWidth = 30;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.getWidth();
  
      doc.addImage(img, "PNG", 10, 10, logoWidth, logoHeight);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      const detailsY = 15;
      const details = [
        "Apnadookan",
        "Klaneettitie 13, 00420 Helsinki, Finland",
        "+358 45 2069311 (WhatsApp)",
        "apnadookan@gmail.com",
      ];
  
      details.forEach((line, index) => {
        const textX = pageWidth - 10 - doc.getTextWidth(line);
        doc.text(line, textX, detailsY + index * 5);
      });
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Invoice", pageWidth / 2, 50, null, null, "center");
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Order Details:", 10, 60);
  
      doc.setFont("helvetica", "normal");
  
      const orderDetails = [
        `Customer Name: ${order.shippingAddress.Address.fullName}`,
        `Order ID: ${order._id}`,
        `Address: ${order.shippingAddress.Address.street}, ${order.shippingAddress.Address.city}`,
        `Drop Off Instructions: ${order.shippingAddress.DropOffInstructions}`,
        `Payment Type: COD`,
      ];
  
      // Wrap the long text in order details
      let yPosition = 70;
      orderDetails.forEach((line) => {
        const wrappedText = doc.splitTextToSize(line, pageWidth - 20); // Wrap text to fit within page
        doc.text(wrappedText, 10, yPosition);
        yPosition += wrappedText.length * 6; // Adjust Y position based on the number of lines
      });
  
      const paymentDate = `Payment Date: ${new Date(order.paidAt).toLocaleDateString()}`;
      const paymentDateX = pageWidth - 10 - doc.getTextWidth(paymentDate);
      doc.text(paymentDate, paymentDateX, 70);
  
      doc.setFont("helvetica", "bold");
      const tableStartY = 110;
      const colHeaders = ["Product", "Quantity", "Price"];
      const colWidths = [130, 30, 30];
      const rowHeight = 10;
      let xPos = 10;
  
      colHeaders.forEach((header, index) => {
        doc.text(header, xPos + 2, tableStartY + 7);
        doc.rect(xPos, tableStartY, colWidths[index], rowHeight);
        xPos += colWidths[index];
      });
  
      let rowStartY = tableStartY + rowHeight;
      doc.setFont("helvetica", "normal");
      order.cart.forEach((item) => {
        xPos = 10;
        const rowData = [`${item.name}`, `${item.quantity}`, `€${item.price}`];
        rowData.forEach((data, index) => {
          doc.text(data, xPos + 2, rowStartY + 7);
          doc.rect(xPos, rowStartY, colWidths[index], rowHeight);
          xPos += colWidths[index];
        });
        rowStartY += rowHeight;
      });
  
      const chargesY = rowStartY + 10;
      doc.setFont("helvetica", "normal");
      doc.text(`Delivery Charges: €${order.otherPrice.deliveryCharges}`, 10, chargesY);
      doc.text(`Other Charges: €${order.otherPrice.otherCharges}`, 10, chargesY + 8);
  
      const totalPriceY = chargesY + 18;
      doc.setFont("helvetica", "bold");
      doc.text(`Total Price: €${order.totalPrice}`, 10, totalPriceY);
  
      // Save the PDF file
      doc.save(`${order._id}_invoice.pdf`);
  
      if (callback) callback();
    };
  };

  const handleDownloadAll = async () => {
    setIsDownloading(true); // Set downloading state to true when starting the download

    // Determine the orders to download based on filtered orders or all orders
    const ordersToDownload =
      filteredallOrders.length > 0 ? filteredallOrders : orders;

    // Generate and download invoices for all filtered orders
    const promises = ordersToDownload.map((order) => {
      return new Promise((resolve) => {
        generateInvoice(order, resolve); // Pass resolve to the generateInvoice function
      });
    });

    // Wait for all invoices to be generated before resetting downloading state
    await Promise.all(promises);

    setIsDownloading(false); // Reset the downloading state after all downloads are done
  };

  const generateInvoice = (order, callback) => {
    const doc = new jsPDF();
  
    const img = new Image();
    img.src = logo;
  
    img.onload = () => {
      const logoWidth = 30;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.getWidth();
  
      doc.addImage(img, "PNG", 10, 10, logoWidth, logoHeight);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      const detailsY = 15;
      const details = [
        "Apnadookan",
        "Klaneettitie 13, 00420 Helsinki, Finland",
        "+358 45 2069311 (WhatsApp)",
        "apnadookan@gmail.com",
      ];
  
      details.forEach((line, index) => {
        const textX = pageWidth - 10 - doc.getTextWidth(line);
        doc.text(line, textX, detailsY + index * 5);
      });
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Invoice", pageWidth / 2, 50, null, null, "center");
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Order Details:", 10, 60);
  
      doc.setFont("helvetica", "normal");
  
      // Wrap long lines to fit within the page width
      const orderDetails = [
        `Customer Name: ${order.shippingAddress.Address.fullName}`,
        `Order ID: ${order._id}`,
        `Address: ${order.shippingAddress.Address.street}, ${order.shippingAddress.Address.city}`,
        `Drop Off Instructions: ${order.shippingAddress.DropOffInstructions}`,
        `Payment Type: COD`,
      ];
  
      let currentY = 70;
      orderDetails.forEach((line) => {
        const maxWidth = pageWidth - 20; // Leave some margin from the page edges
        const splitText = doc.splitTextToSize(line, maxWidth);
        doc.text(splitText, 10, currentY);
        currentY += splitText.length * 8; // Adjust Y-position based on number of lines
      });
  
      // Payment Date remains in the same position
      const paymentDate = `Payment Date: ${new Date(order.paidAt).toLocaleDateString()}`;
      const paymentDateX = pageWidth - 10 - doc.getTextWidth(paymentDate);
      doc.text(paymentDate, paymentDateX, 70); // Keep it at Y = 70
  
      doc.setFont("helvetica", "bold");
      const tableStartY = currentY + 20; // Start the table after the last order detail
      const colHeaders = ["Product", "Quantity", "Price"];
      const colWidths = [130, 30, 30];
      const rowHeight = 10;
      let xPos = 10;
  
      colHeaders.forEach((header, index) => {
        doc.text(header, xPos + 2, tableStartY + 7);
        doc.rect(xPos, tableStartY, colWidths[index], rowHeight);
        xPos += colWidths[index];
      });
  
      let rowStartY = tableStartY + rowHeight;
      doc.setFont("helvetica", "normal");
      order.cart.forEach((item) => {
        xPos = 10;
        const rowData = [`${item.name}`, `${item.quantity}`, `€${item.price}`];
        rowData.forEach((data, index) => {
          doc.text(data, xPos + 2, rowStartY + 7);
          doc.rect(xPos, rowStartY, colWidths[index], rowHeight);
          xPos += colWidths[index];
        });
        rowStartY += rowHeight;
      });
  
      // Add Delivery Charges and Other Charges above Total Price
      const chargesY = rowStartY + 10;
      doc.setFont("helvetica", "normal");
      doc.text(`Delivery Charges: €${order.otherPrice.deliveryCharges}`, 10, chargesY);
      doc.text(`Other Charges: €${order.otherPrice.otherCharges}`, 10, chargesY + 8);
  
      // Total Price below Delivery Charges and Other Charges
      const totalPriceY = chargesY + 20;
      doc.setFont("helvetica", "bold");
      doc.text(`Total Price: €${order.totalPrice}`, 10, totalPriceY);
  
      // Convert the PDF to Blob and open it in a new tab
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
  
      if (callback) callback();
    };
  };
  
  const filteredOrdersByDate = (
    filteredallOrders.length > 0 ? filteredallOrders : orders
  ).filter((order) => {
    const idMatch =
      order?._id && order._id.toLowerCase().includes(searchQuery.toLowerCase());
    const nameMatch =
      order?.shippingAddress?.Address?.fullName &&
      order.shippingAddress.Address.fullName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    const cartMatch =
      order?.cart?.name &&
      order.cart.name.toLowerCase().includes(searchQuery.toLowerCase());

    return idMatch || nameMatch || cartMatch;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center ">
        <RotatingLines
          height="80"
          width="80"
          color="#003E00"
          ariaLabel="loading"
        />
      </div>
    );
  }

  const pageCount = Math.ceil(filteredallOrders.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  // const currentItems = filteredOrders.slice(offset, offset + itemsPerPage);
  const currentItems = filteredOrdersByDate.slice(
    offset,
    offset + itemsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="md:px-10 px-8">
      <div>
        <p className="text-[#475467] text-xl quicksand-Bold md:my-4 my-3">
          Recent Return Orders
        </p>
      </div>
      <div className="md:flex flex-wrap justify-between items-center gap-4">
        <div className="flex  items-center gap-4">
          <div className="border border-gray-300 flex p-3 rounded-lg gap-2 items-center">
            <img src={search} alt="Search icon" />
            <input
              type="text"
              placeholder="Search"
              className="md:w-[280px] w-full outline-none"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="flex items-center gap-2">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="From Date"
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="To Date"
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div>
        </div>
        <div className="flex gap-2 md:mt-0 mt-2">
          <button
            onClick={handleApplyFilter}
            className="bg-[#003E00] text-white md:text-base text-sm md:px-4 px-2 py-2 rounded-lg "
          >
            Apply Filter
          </button>
          {/* <button
            onClick={handleDownloadAll}
            className="flex items-center gap-2 text-white  md:text-base text-sm bg-[#003E00] md:px-4 px-2 py-2 rounded-lg"
          >
            <FaDownload />
            Download All
          </button> */}
          <button
            onClick={handleDownloadAllInvoice}
            className="flex items-center justify-center bg-[#003E00] text-white px-4 py-2 rounded-lg"
            disabled={isDownloading}
          >
            {isDownloading ? (
              <RotatingLines height="24" width="24" color="white" />
            ) : (
              <>
                <FaDownload />
                <span className="ml-2">Download All</span>
              </>
            )}
          </button>
        </div>
      </div>

      <div className="my-10">
        <div className="overflow-x-auto">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #EAECF0",
              borderRadius: "20px",
            }}
          >
            <thead className="bg-gray-50">
              <tr className="border-b">
                <th
                  style={{
                    textAlign: "left",
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    padding: "12px 20px",
                  }}
                >
                  <p className="text-[#475467] inter-Medium text-xs">
                    Order No.
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Customer Name
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Total Price
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">Status</p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}></th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((order, index) => (
                <tr key={order._id} className="border-b">
                  <td
                    style={{
                      padding: "16px 20px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-[#101828] inter-Medium text-sm">
                      {String(offset + index + 1).padStart(6, "0")}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {order.shippingAddress.Address.fullName}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      €{order.totalPrice}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <div className="bg-[#ECFDF3] rounded-xl w-[80px]">
                      <p className="inter-Medium bg-[#ECFDF3] p-2 rounded-xl text-xs text-[#027A48]">
                        {order.status}
                      </p>
                    </div>
                  </td>

                  <td style={{ padding: "16px 20px" }}>
                    <Link to={`Return-Order-Details/${order._id}`}>
                      <button>
                        <AiOutlineArrowRight size={18} />
                      </button>
                    </Link>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <button onClick={() => generateInvoice(order)}>
                      <FaDownload size={18} color="#003E00" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakClassName={"page-item"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ReturnRecentOrders;
