import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginImage from "../../../../assets/images/login.png";
import "../../../../index.css";
import API from "../../../../api/apiService";
import { ENDPOINTS } from "../../../../api/apiRoutes";
import { useDispatch } from "react-redux";
import { login } from "../../../../redux/slices/loginSlice";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

function NewPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });


  const handleSubmit = async (values, { setSubmitting }) => {
    const email = localStorage.getItem("email"); // Retrieve email from localStorage or another source
    if (!email) {
      toast.error("Email is missing. Please restart the process.");
      setSubmitting(false);
      return;
    }

    try {
      const response = await API.post(ENDPOINTS.RESET_PASS, {
        email,
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      if (response.message) {
        toast.success(response.message || "Password reset successful!");
        navigate("/login"); 
      } else {
        toast.error("Failed to reset password.");
      }
    } catch (error) {
      toast.error(
        error.response?.message || "An error occurred while resetting password."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="md:flex justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00] flex justify-between flex-col">
          <div className="p-4">
            <IoArrowBackCircleOutline
              size={60}
              color="white"
              onClick={() => navigate("/")}
              className="cursor-pointer"
            />
          </div>
          <div>
            <img src={loginImage} alt="product" className="" />
          </div>
        </div>
        <div className="md:w-[50%] rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div>
            <h1 className="md:text-4xl text-3xl font-bold">
              Change Your Password
            </h1>
            <p className="text-xl font-semibold mt-4 mb-6">
              Enter New Password
            </p>

            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-2 md:w-[450px]">
                  <div className="flex flex-col relative">
                    <label className="md:mt-4 mt-2 font-semibold">
                      New Password
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your Password"
                      className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                    <div
                      className="absolute right-3 top-12 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IoEyeOutline size={24} />
                      ) : (
                        <IoEyeOffOutline size={24} />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col relative">
                    <label className="md:mt-4 mt-2 font-semibold">
                      Confirm New Password
                    </label>
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm your Password"
                      className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-red-500"
                    />
                    <div
                      className="absolute right-3 top-12 cursor-pointer"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <IoEyeOutline size={24} />
                      ) : (
                        <IoEyeOffOutline size={24} />
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full py-[10px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-6"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Confirming..." : "Confirm"}
                  </button>
                </Form>
              )}
            </Formik>

            <p className="font-semibold text-center mt-8">
              Don't have an account?{" "}
              <button
                className="text-[#004D00]"
                onClick={() => navigate("/register")}
              >
                Signup!
              </button>
            </p>
          </div>
          <p className="text-gray-400 relative top-8 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
