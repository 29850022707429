import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Layout from "./Layout/Layout";
import Home from "./pages/Home/Home";
import Order_Placement from "./pages/Order-Placement/Order_Placement";
import ProductPage from "./pages/ProductPage/ProductPage";
import OrderOPtions from "./pages/Order-Options/OrderOPtions";
import Login from "./pages/Admin/Auth/Login/Login";
import Orders from "./pages/Admin/Orders/Orders";
import Inventory from "./pages/Admin/Inventory/Inventory";
import AddItem from "./pages/Admin/AddItems/AddItem";
import Item from "./pages/Admin/Item/Item";
import OrderDetail from "./pages/Admin/OrderDetail/OrderDetail";
import Banners from "./pages/Admin/Banners/Banners";
import Profile from "./pages/Admin/Profile/Profile";
import FoodCategories from "./pages/Admin/Food_Categories/FoodCategories";
import Register from "./pages/Admin/Auth/Register/Register";
import Verfication from "./pages/Admin/Auth/Verification/Verification";
import Protected from "./components/ProtectedRoutes/Protected";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import Store from './redux/store'
import API, { ENDPOINTS } from "./api/apiService";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import 'react-toastify/dist/ReactToastify.css'; 
import { ToastContainer } from 'react-toastify';
import OrderSuccess from "./pages/OrderSuccess/OrderSuccess";
import SubCategories from "./pages/Admin/Sub_Categories/Sub_Categories";
import Contact from "./pages/Contact/Contact";
import Coupons_Code from "./pages/Admin/Coupons_Code/Coupons_Code";
import ProfileEdit from "./pages/ProfileEdit/ProfileEdit"
import Setting from "./pages/ManageSetting/Setting";
import About from "./pages/AboutUs/About";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import OrderTrack from "./pages/OrderTrack/OrderTrack";
import ProfileInformation from "./components/ProfileInformation/ProfileInformation";
import QA from "./pages/QA/QA";
import Payment from "./pages/Payment/Payment";
import Shipping from "./pages/Shipping/Shipping";
import Refund from "./pages/Refund/Refund";
import Forgotpass from "./pages/Admin/Auth/Forgotpass/Forgotpass";
import ForgotpassVerify from "./pages/Admin/Auth/ForgotpassVerify/ForgotpassVerify";
import NewPassword from "./pages/Admin/Auth/NewPassword/NewPassword";
import Order_Details from "./pages/Order_Details/Order_Details";
import OrderReturn from "./pages/OrderReturn/OrderReturn";
import OrderAndReturn from "./pages/Admin/OrderReturns/OrderAndReturn";
import Return_OrderDetails from "./pages/Return_OrderDetails/Return_OrderDetails";



export default function App() {
  const [stripeApiKey, setStripeApiKey] = useState("");

  async function getStripeApikey() {
    // const { data } = await API.get(`${ENDPOINTS.STRIPE_KEY}`);
    const { data } = await axios.get(`https://apnadookan.com/api/stripeapikey`);
    setStripeApiKey(data.stripeApikey);
  }

  useEffect(()=>{
    // Store.dispatch()
    getStripeApikey()
  })

  return (
    <>
      <BrowserRouter>
      {stripeApiKey && (
        <Elements stripe={loadStripe(stripeApiKey)}>
          <Routes>
            <Route
              path="order-options"
              element={
                // <ProtectedRoute>
                  <OrderOPtions />
                // </ProtectedRoute>
              }
            />
          </Routes>
        </Elements>
      )}
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="verification" element={<Verfication />} />
          <Route path="forgotpass" element={<Forgotpass />} />
          <Route path="forgotpassverify" element={<ForgotpassVerify />} />
          <Route path="newPassword" element={<NewPassword />} />
          <Route path="contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
          <Route path="products" element={<Order_Placement />} />
          <Route path="product/:id" element={<ProductPage />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="payment-success" element={<OrderSuccess />} />
          <Route path="profileEdit" element={<ProfileEdit />} />
          <Route path="setting" element={<Setting />} />
          <Route path="about" element={<About />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="profile" element={<OrderTrack />} />
          <Route path="orders" element={<OrderTrack />} />
          <Route path="profileInformation" element={<ProfileInformation />} />
          <Route path="QA" element={<QA />} />
          <Route path="payment" element={<Payment />} />
          <Route path="shipping" element={<Shipping />} />
          <Route path="refund" element={<Refund />} />
          <Route path="orderDetails/:id" element={<Order_Details />} />
          <Route path="order-return" element={<OrderReturn />} />
       
        
          
          
            {/* <Route path="order-options" element={<OrderOPtions />} /> */}
          {/* <Route path="/admin" element={<Layout/>}> */}
          <Route
          path="/admin"
          element={<Protected Component={Layout} />}
        >
          <Route index element={<FoodCategories />} />
            <Route path="Orders" element={<Orders />} />
            <Route path="inventory/add-items" element={<AddItem />} />
            <Route path="inventory/edit-items" element={<AddItem />} />
            <Route path="inventory/item" element={<Item />} />
            <Route path="inventory" element={<Inventory />} />
            <Route path="Orders/order-detail/:id" element={<OrderDetail />} />
            <Route path="banners" element={<Banners />} />
            <Route path="profile" element={<Profile />} />
            <Route path="food-categories" element={<FoodCategories />} />
            <Route path="sub-categories" element={<SubCategories />} />
            <Route path="coupons-code" element={<Coupons_Code />} />
            <Route path="orders-return" element={<OrderAndReturn />} />
            <Route path="orders-return/Return-Order-Details/:id" element={<Return_OrderDetails />} />
          </Route>
        </Routes>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      </BrowserRouter>
    </>
  );
}
