import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../../api/apiService";
import { ENDPOINTS } from "../../../../api/apiRoutes";
import login from "../../../../assets/images/login.png";
import "../../../../index.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object({
  username: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .required("Password is required"),
});

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // const handleSubmit = async (
  //   values,
  //   { setSubmitting, setErrors, setStatus }
  // ) => {
  //   try {
  //     const userName = values.username;

  //     localStorage.setItem("userName", JSON.stringify(userName));
  //     const response = await API.post(ENDPOINTS.REGISTER, values);

  //     if (response.message === "User Already Exist with this username") {
  //       toast.error("User already exists with this username.");
  //     } else if (response.message === "User Already Exist") {
  //       toast.error("User already exists with this email.");
  //     } else {
  //       setStatus({
  //         success:
  //           "Registration successful! Please check your email for verification.",
  //       });
  //       toast.success("Registration successful! Please check your email.");
  //       navigate("/verification");
  //     }
  //   } catch (error) {
  //     const errorMessage =
  //     error.response?.message || "User Already Exist.";
  //       // error.response?.message || "An error occurred during registration.";
  //     setErrors({ api: errorMessage });
  //     toast.error(errorMessage);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };
  // const handleSubmit = async (
  //   values,
  //   { setSubmitting, setErrors, setStatus }
  // ) => {
  //   try {
  //     const { username, email } = values;
  
  //     // Store the email in localStorage
  //     localStorage.setItem("email", email);
  //     localStorage.setItem("userName", JSON.stringify(username));
  
  //     const response = await API.post(ENDPOINTS.REGISTER, values);
  
  //     if (response.message === "User Already Exist with this username") {
  //       toast.error("User already exists with this username.");
  //     } else if (response.message === "User Already Exist") {
  //       toast.error("User already exists with this email.");
  //     } else {
  //       setStatus({
  //         success:
  //           "Registration successful! Please check your email for verification.",
  //       });
  //       toast.success("Registration successful! A verification code has been sent to your email.");
  //       navigate("/verification");
  //     }
  //   } catch (error) {
  //     const errorMessage = error.response?.message || "An error occurred during registration.";
  //     setErrors({ api: errorMessage });
  //     toast.error(errorMessage);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  //new issue that point out 
  const handleSubmit = async (values, { setSubmitting, setErrors, setStatus }) => {
    try {
      const { username, email } = values;
  
      // Store the email in localStorage
      localStorage.setItem("email", email);
      localStorage.setItem("userName", JSON.stringify(username));
  
      const response = await API.post(ENDPOINTS.REGISTER, values);
  
      if (response.message === "User Already Exist with this username") {
        toast.error("User already exists with this username.");
      } else if (response.message === "User Already Exist") {
        toast.error("User already exists with this email.");
      } else {
        setStatus({
          success:
            "Registration successful! Please check your email for verification.",
        });
        toast.success("Registration successful! A verification code has been sent to your email.");
        navigate("/verification");
      }
    } catch (error) {
      // Check if error.response exists and handle accordingly
      const errorMessage = error.response?.data?.message || error.response?.message || "An error occurred during registration.";
  
      if (errorMessage === "User Already Exist with this username") {
        toast.error("User already exists with this username.");
      } else if (errorMessage === "User Already Exist") {
        toast.error("User already exists with this email.");
      } else {
        setErrors({ api: errorMessage });
        toast.error(errorMessage);
      }
    } finally {
      setSubmitting(false);
    }
  };
  
  

  return (
    <div className="w-[100%]">
      <div className="md:flex justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00] flex items-end">
          <img src={login} alt="product" className="" />
        </div>
        <div className="md:w-[50%] rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div>
            <h1 className="md:text-4xl text-3xl font-bold">Welcome Back!</h1>
            <p className="text-xl font-semibold mt-4 mb-6">
              Signup to your account
            </p>

            <Formik
              initialValues={{ username: "", email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-2 md:w-[450px]">
                  <label className="font-semibold">UserName</label>
                  <Field
                    type="text"
                    name="username"
                    placeholder="Enter your Name"
                    className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-500"
                  />

                  <label className="md:mt-2 mt-2 font-semibold">Email</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                    className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />

                  <label className="md:mt-2 mt-2 font-semibold">Password</label>
                  <div className="relative">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your Password"
                      className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                    />
                    <span
                      className="absolute right-2 top-3 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IoEyeOutline size={20} />
                      ) : (
                        <IoEyeOffOutline size={20} />
                      )}
                    </span>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500"
                  />

                  <button
                    type="submit"
                    className="w-full py-[10px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-6 inter-Semi"
                    disabled={isSubmitting}
                  >
                    Signup
                  </button>
                </Form>
              )}
            </Formik>

            <p className="font-semibold text-center mt-8">
              Already have an account?{" "}
              <button
                className="text-[#004D00]"
                onClick={() => navigate("/login")}
              >
                Login!
              </button>
            </p>
          </div>
          <p className="text-gray-400 mt-6 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;

