import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import search from "../../assets/images/search-lg.png";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import ReactPaginate from "react-paginate";
import "./RecentInventory.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import { RotatingLines } from 'react-loader-spinner';

function RecentInventory() {
  const [searchQuery, setSearchQuery] = useState("");
  const [inventories, setInventories] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(false); 

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInventories = async () => {
      setLoading(true);
      try {
        const response = await API.get(ENDPOINTS.GET_ALLPRODUCTS_BYADMIN);
        setInventories(response || []);
        setFilteredItems(response || []);
      } catch (error) {
        console.error("Error fetching inventories:", error);
      }
      finally {
        setLoading(false); 
      }
    };

    fetchInventories();
  }, []);

  useEffect(() => {
    const filtered = inventories.filter(
      (item) =>
        item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.itemNo?.includes(searchQuery)
    );
    setFilteredItems(filtered);
    setCurrentPage(0);
  }, [searchQuery, inventories]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredItems.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const formatProductNumber = (number) => {
    if (!number) return "N/A";
    const digitsArray = number.toString().split("");
    const sortedDigits = digitsArray.sort();
    return parseInt(sortedDigits.join(""), 10);
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const handleEdit = (item) => {
    navigate("/admin/inventory/edit-items", { state: { item } });
  };

  const handleDelete = async (item) => {
    if (!item || !item._id) return;

    const token = getToken();
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      await API.deleteResource(`${ENDPOINTS.DELETE_INVENTORIES}/${item._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const response = await API.get(ENDPOINTS.GET_ALLPRODUCTS_BYADMIN);
      setInventories(response || []);
      setFilteredItems(response || []);

      toast.success("Item deleted successfully!");
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item.");
    }
  };

  return (
    <div className="md:px-10 px-8 mt-10">
      {/* <div className="flex justify-between items-center gap-4 my-4 flex-wrap">
        <div className="flex flex-col gap-2">
          <p className="text-[#020617] text-4xl BBoldFont">Inventory Items</p>
          <p className="text-[#696969] text-base NormalFont">
            Overview of your Inventory
          </p>
        </div>
      </div> */}

      <div className="flex md:flex-row flex-col justify-between items-center gap-4 md:mt-10 mt-5">
        <div>
          <div className="border border-gray-300 flex p-3 rounded-lg gap-2 items-center">
            <img src={search} alt="Search Icon" />
            <input
              type="text"
              placeholder="Search"
              className="md:w-[280px] outline-none"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center my-10">
        <RotatingLines
              height="80"
              width="80"
              color="#003E00"
              ariaLabel="loading"
            />
        </div>
      ) : (
      <div className="my-10">
        <div className="overflow-x-auto">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #EAECF0",
              borderRadius: "20px",
            }}
          >
            <thead className="bg-gray-50 ">
              <tr className="border-b">
                <th
                  style={{
                    textAlign: "left",
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    padding: "12px 20px",
                  }}
                >
                  <p className="text-[#475467] inter-Medium text-xs">
                    Item No.
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Items Name
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Category
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Quantity Available
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">Price</p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">Status</p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">Actions</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index} className="border-b">
                  <td
                    style={{
                      padding: "16px 20px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-[#101828] inter-Medium text-sm">
                      {index+1}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {item.name}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {item.category?.name}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {item.quantity}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {item.price}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <div
                      className={`bg-[#ECFDF3] rounded-xl w-[80px] flex justify-center`}
                    >
                      <p
                        className={`inter-Medium p-2 rounded-xl text-xs ${
                          item.isActive ? "text-[#027A48]" : "text-[#B42318]"
                        }`}
                      >
                        {item.isActive ? "In Stock" : "Not Available"}
                      </p>
                    </div>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <div className="flex gap-5">
                      <button onClick={() => handleEdit(item)}>
                        <FaRegEdit color="#003E00" size={20} />
                      </button>
                      <button onClick={() => handleDelete(item)}>
                        <MdDelete color="#003E00" size={20} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakClassName={"page-item"}
          pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
        />
      </div>
    )}
    </div>
  );
}

export default RecentInventory;
