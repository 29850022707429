
import React from "react";
import "../../index.css";
import { useSelector } from "react-redux";
import { FaDownload } from "react-icons/fa6";
import { jsPDF } from "jspdf"; // Import jsPDF
import logo from "../../assets/images/apnadookan.png";

// Generate invoice function (modified to create a new PDF per order)
const generateInvoice = (order) => {
  const doc = new jsPDF();
  const img = new Image();
  img.src = logo;

  // Return a Promise to handle image loading asynchronously
  return new Promise((resolve) => {
    img.onload = () => {
      const logoWidth = 30;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.getWidth();

      // Add logo to the document
      doc.addImage(img, "PNG", 10, 10, logoWidth, logoHeight);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      const detailsY = 15;
      const details = [
        "Apnadookan",
        "Klaneettitie 13, 00420 Helsinki, Finland",
        "+358 45 2069311 (WhatsApp)",
        "apnadookan@gmail.com",
      ];

      details.forEach((line, index) => {
        const textX = pageWidth - 10 - doc.getTextWidth(line);
        doc.text(line, textX, detailsY + index * 5);
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Invoice", pageWidth / 2, 50, null, null, "center");

      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Order Details:", 10, 60);

      doc.setFont("helvetica", "normal");

      const orderDetails = [
        `Customer Name: ${order.shippingAddress.Address.fullName}`,
        `Order ID: ${order._id}`,
        `Address: ${order.shippingAddress.Address.street}, ${order.shippingAddress.Address.city}`,
        `Drop Off Instructions: ${order.shippingAddress.DropOffInstructions}`,
        `Payment Type: COD`,
      ];

      let yPosition = 70;
      orderDetails.forEach((line) => {
        const wrappedText = doc.splitTextToSize(line, pageWidth - 20); // Wrap text to fit within page
        doc.text(wrappedText, 10, yPosition);
        yPosition += wrappedText.length * 6; // Adjust Y position based on the number of lines
      });

      const paymentDate = `Payment Date: ${new Date(order.paidAt).toLocaleDateString()}`;
      const paymentDateX = pageWidth - 10 - doc.getTextWidth(paymentDate);
      doc.text(paymentDate, paymentDateX, 70);

      doc.setFont("helvetica", "bold");
      const tableStartY = 110;
      const colHeaders = ["Product", "Quantity", "Price"];
      const colWidths = [130, 30, 30];
      const rowHeight = 10;
      let xPos = 10;

      colHeaders.forEach((header, index) => {
        doc.text(header, xPos + 2, tableStartY + 7);
        doc.rect(xPos, tableStartY, colWidths[index], rowHeight);
        xPos += colWidths[index];
      });

      let rowStartY = tableStartY + rowHeight;
      doc.setFont("helvetica", "normal");
      order.cart.forEach((item) => {
        xPos = 10;
        const rowData = [`${item.name}`, `${item.quantity}`, `€${item.price}`];
        rowData.forEach((data, index) => {
          doc.text(data, xPos + 2, rowStartY + 7);
          doc.rect(xPos, rowStartY, colWidths[index], rowHeight);
          xPos += colWidths[index];
        });
        rowStartY += rowHeight;
      });

      const chargesY = rowStartY + 10;
      doc.setFont("helvetica", "normal");
      doc.text(`Delivery Charges: €${order.otherPrice.deliveryCharges}`, 10, chargesY);
      doc.text(`Other Charges: €${order.otherPrice.otherCharges}`, 10, chargesY + 8);

      const totalPriceY = chargesY + 18;
      doc.setFont("helvetica", "bold");
      doc.text(`Total Price: €${order.totalPrice}`, 10, totalPriceY);

      // Save the PDF for this order
      doc.save(`${order._id}_invoice.pdf`);

      resolve(); // Resolve the promise when the PDF is ready
    };
  });
};

function TotalReturnOrders() {
  const orders = useSelector((state) => state.orders.orders);

  const handleDownloadAll = async () => {
    if (orders && orders.length > 0) {
      // Loop through all orders and generate separate invoices for each
      for (const order of orders) {
        await generateInvoice(order); // Wait for each invoice to be generated before moving to the next one
      }
    } else {
      alert("No orders available to download");
    }
  };

  return (
    <div className="md:px-10 px-8 md:py-10 py-5">
      <div className="flex md:gap-10 gap-4 flex-col">
        <div className="flex flex-wrap justify-between items-center gap-4 ">
          <div className="flex flex-col gap-2">
            <p className="text-[#020617] text-4xl BBoldFont ">Orders Return</p>
            <p className="text-[#696969] text-base NormalFont">
              Here is a quick overview about your Orders Return
            </p>
          </div>
          <div className="flex gap-3">
            <div>
              <button
                onClick={handleDownloadAll}
                className="flex items-center justify-between bg-[#003E00] text-white px-4 py-2 rounded-lg space-x-3 "
              >
                <FaDownload />
                <span>Download All</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalReturnOrders;
