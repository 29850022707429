// try to get the updating qunaitity from snack and dispatch to slider cart

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BiMinus, BiPlus } from "react-icons/bi";
import { PiShoppingCartLight } from "react-icons/pi";
import API from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateQuantity } from "../../redux/slices/cartSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import { toast } from "react-toastify";
import { FaHeart, FaRegHeart } from "react-icons/fa";

const getUnitsAndNumbers = (name) => {
  const regex =
    /\b(\d+(\.\d+)?)\s*(kg|grams|g|lbs|pounds|oz|ml|liters|litre|pc)\b/gi;
  const matches = [];

  let match;
  while ((match = regex.exec(name)) !== null) {
    matches.push({ value: match[1], unit: match[3] });
  }

  return matches;
};

function Product() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  // const [count, setCount] = useState(1);
  const [maxReached, setMaxReached] = useState(false);
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const productInCart = cartItems.find((item) => item._id === id);
  const [count, setCount] = useState(
    productInCart ? productInCart.quantity : 1
  );

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await API.get(`/inventory/${id}`);
        setProduct(response);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProduct();
  }, [id]);

  // useEffect(() => {
  //   setCount(1);
  // }, [id]);
  useEffect(() => {
    if (productInCart) {
      setCount(productInCart.quantity);
    } else {
      setCount(1);
    }
  }, [id, productInCart]);

  const handleIncrement = () => {
    if (count < 100) {
      setCount(count + 1);
      setMaxReached(false);
      dispatch(updateQuantity({ productId: id, quantity: count + 1 }));
    } else {
      setMaxReached(true);
    }
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setMaxReached(false);
      dispatch(updateQuantity({ productId: id, quantity: count - 1 }));
    }
  };

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        _id: id,
        quantity: count,
        price: product.price,
        name: product.name,
        imageUrl: product.imageUrl,
      })
    );
    toast.success("Item Added To Cart");
  };

  if (!product) {
    return <p></p>;
  }

  const handleAddToWishlist = (e) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
      toast.error("Item Removed from Wishlist");
    } else {
      dispatch(addToWishlist(product));
      toast.success("Item Added to Wishlist");
    }
  };

  const handleCheckout = () => {
    navigate("/order-options", { state: { cartItems } });
  };

  const isInWishlist = wishlistItems.some((item) => item._id === product._id);

  // Get units and numbers from the product name
  const unitsAndNumbers = getUnitsAndNumbers(product.name);

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px]">
      <div className="flex md:flex-row flex-col w-[100%] border border-gray-200 rounded-lg">
        <div className="relative">
          <img
            src={product.imageUrl || product.imageUrl}
            alt="product"
            className="w-full md:h-[450px] z-0 rounded-md"
          />
          <div
            className="absolute top-4 md:right-8 right-2 w-[44px] h-[44px] cursor-pointer"
            onClick={handleAddToWishlist}
          >
            {isInWishlist ? (
              <FaHeart className="text-red-500" size={28} />
            ) : (
              <FaRegHeart className="text-gray-300" size={28} />
            )}
          </div>
        </div>

        <div className="md:w-[704px] z-10 relative md:-left-10 bg-white md:p-6 p-4">
          <h1 className="md:text-[36px] text-[28px] MediumFont">
            {product.name}
          </h1>
          <div className="flex flex-row  items-center md:gap-[200px] gap-[130px]">
            <p className="text-2xl MediumFont my-4">€ {product.price}</p>
            <div className="relative my-4">
              <p className="text-xl font-semibold">Status</p>
              <h2 className="text-base NormalFont">
                {product.isActive ? "In stock" : "Out of stock"}
              </h2>
            </div>
          </div>
          <p className="text-xl font-semibold">Description</p>
          <p className="mb-4 mt-2">{product.description}</p>

          <div className="flex gap-2 mb-4 flex-wrap">
            <p className="text-xl font-semibold">Quantity</p>
            <div className="flex gap-4 bg-gray-200 py-1 px-4 mx-3">
              <BiMinus
                className={`self-center cursor-pointer ${
                  count === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleDecrement}
              />
              <p>{count}</p>
              <BiPlus
                className="self-center cursor-pointer"
                onClick={handleIncrement}
              />
            </div>
            {maxReached && (
              <p className="text-red-500 text-sm mt-1">
                Max quantity of 100 reached
              </p>
            )}
          </div>

          <div>
         <div className="flex gap-[40px]">
            <div className="flex mb-4">
              <div className="md:w-[200px] w-[120px]">
                <p className="text-xl font-semibold ">Unit</p>
                <h2 className="text-base NormalFont ml-3">{product.unitType}</h2>
                {/* <ul>
                  {unitsAndNumbers.map((item, index) => (
                    <li key={index} className="text-base NormalFont">
                      {item.value} {item.unit}
                    </li>
                  ))}
                </ul> */}
              </div>
            </div>
            <div className="flex mb-4">
              <div className="md:w-[200px] w-[120px]">
                <p className="text-xl font-semibold">Weight</p>
                <h2 className="text-base NormalFont ml-4">{product.unitValue}</h2>
              </div>
            </div>
            </div>

            <div className="flex md:gap-8 gap-4 mb-4">
              <button
                className="md:w-[220px] w-[150px] md:p-3 p-2 text-center bg-[#ACE03A] text-white MediumFont rounded-3xl md:text-xl text-lg"
                onClick={handleAddToCart}
                // disabled={!product.isActive}
              >
                Add to Cart
              </button>

              {cartItems.length > 0 && (
                <button
                  className="md:w-[220px] w-[150px] md:p-3 p-2 text-center bg-[#ACE03A] text-white MediumFont rounded-3xl md:text-xl text-lg"
                  onClick={handleCheckout}
                >
                  Checkout
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
