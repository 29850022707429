import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import icon1 from "../../assets/images/Chevron left.png";
import icon2 from "../../assets/images/Chevron right.png";
import icon3 from "../../assets/images/heart.png";
import Slider from "react-slick";
import { addToCart, updateQuantity } from "../../redux/slices/cartSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomLink from "../CustomLink/CustomLink";
import "../../../src/index.css";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import "../../index.css";
import { toast } from "react-toastify";
import "./PeopleBuy.css";
import { PiShoppingCartLight } from "react-icons/pi";
import ReactPaginate from "react-paginate";
import { TailSpin } from "react-loader-spinner";

function PeopleBuy() {
  const { searchQuery } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const sliderRefs = useRef([]);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [categories, setCategories] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const categoriesPerPage = 5;
  const [totalPages, setTotalPages] = useState(0);
  const [productCounts, setProductCounts] = useState({});
  const navigate = useNavigate();

  const fetchCategories = async (page) => {
    try {
      setLoading(true);
      const response = await API.get(
        `${ENDPOINTS.GET_ALLPRODUCTS}?page=${page}&limit=${categoriesPerPage}`
      );
      const products = response.inventoryItems || [];
      const totalItems = response.totalItems || 0;

      if (!Array.isArray(products)) {
        throw new Error("Expected an array of products");
      }

      const uniqueCategoriesMap = new Map();
      products.forEach((item) => {
        if (item.category && item.category._id && item.category.name) {
          const categoryId = item.category._id;
          if (uniqueCategoriesMap.has(categoryId)) {
            uniqueCategoriesMap.get(categoryId).products.push(item);
          } else {
            uniqueCategoriesMap.set(categoryId, {
              _id: categoryId,
              name: item.category.name,
              products: [item],
            });
          }
        }
      });

      setCategories(Array.from(uniqueCategoriesMap.values()));
      setTotalPages(Math.ceil(totalItems / categoriesPerPage));
    } catch (err) {
      setError(err.message || "Error fetching categories");
    } finally {
      setLoading(false);
    }
  };

  const handleSlideClick = (param) => {
    // navigate(`/products?name=${param.name}&id=${param.id}`);
    navigate(`/products?name=${param.name.replace(/ /g, '+')}&id=${param.id}`);
  };

  const handleIncrement = (productId) => {
    const updatedCount = (productCounts[productId] || 1) + 1;
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: updatedCount,
    }));
    dispatch(updateQuantity({ productId, quantity: updatedCount }));
  };

  const handleDecrement = (productId) => {
    const updatedCount = (productCounts[productId] || 1) - 1;
    if (updatedCount < 1) return;
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: updatedCount,
    }));
    dispatch(updateQuantity({ productId, quantity: updatedCount }));
  };

  useEffect(() => {
    fetchCategories(currentPage);
  }, [currentPage]);

  const filteredCategories = categories
    .map((category) => ({
      ...category,
      products:
        category.products?.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [],
    }))
    .filter((category) => category.products.length > 0);

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400, // Applies for screens <= 1400px and > 1200px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200, // Breakpoint for 980px to 1200px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) {
        slider.slickPrev();
      }
    });
  };

  const handleNextClick = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) {
        slider.slickNext();
      }
    });
  };

  const handleSeeMoreClick = () => {
    setVisibleCount(categories.length);
  };

  const handleAddToWishlist = (e, product) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
      toast.error("Item Removed from Wishlist");
    } else {
      dispatch(addToWishlist(product));
      toast.success("Item Added to Wishlist");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[100vh]">
        <TailSpin
          height="80"
          width="80"
          color="#ACE03A"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }
  if (error) {
    return <div className="text-center text-red-500">Item Not Found</div>;
  }

  if (categories.length === 0) {
    return <div className="text-center">No categories available.</div>;
  }

  const handleAddToCart = (product) => {
    if (product) {
      dispatch(addToCart({ ...product, quantity: count }));
      toast.success("Item Added To Cart");
    }
  };

  const offset = (currentPage - 1) * categoriesPerPage;
  const displayedCategories = filteredCategories.slice(
    offset,
    offset + categoriesPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] mt-10">
      <div className="flex justify-between items-center ">
        <div>
          <p className="MediumFont text-2xl text-[#344054]">People Also Buy</p>
        </div>
        <div className="flex gap-[16px]">
          <img
            src={icon1}
            alt="Previous"
            onClick={handlePrevClick}
            className="cursor-pointer"
          />
          <img
            src={icon2}
            alt="Next"
            onClick={handleNextClick}
            className="cursor-pointer"
          />
        </div>
      </div>

      {filteredCategories.map((category, index) => {
        return (
          <div key={category._id} className="mt-6">
            <div className="flex justify-between"></div>

            <div className="mt-6 w-full">
              <Slider
                ref={(el) => (sliderRefs.current[index] = el)}
                {...settings}
                className="slider"
              >
                {category.products.map((product) => (
                  <Link
                    key={product._id}
                    to={`/product/${product._id}`}
                    className="flex flex-col xl:h-[220px] md:h-[220px] h-[210px] shadow-md rounded-2xl overflow-hidden "
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    <div className="relative flex items-center justify-center">
                      <img
                        src={product.imageUrl}
                        alt=""
                        className="bg-cover h-[120px] w-[140px]  rounded-b-xl "
                      />
                      <div
                        className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleAddToWishlist(e, product);
                        }}
                      >
                        {wishlistItems.some(
                          (item) => item._id === product._id
                        ) ? (
                          <FaHeart className="text-red-500" size={28} />
                        ) : (
                          <FaRegHeart className="text-gray-300" size={28} />
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2   px-4">
                      <p className="text-xs  text-[#ADADAD] ">
                        {category.name}
                      </p>
                      <p className="font-semibold text-[16px] text-[#253D4E] ">
                        {product.name.length > 16
                          ? product.name.slice(0, 16) + "..."
                          : product.name}
                      </p>

                      <div className="flex justify-between">
                        <div className="flex gap-2">
                          {product.retailPrice > 0 && (
                            <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
                              € {product.retailPrice}
                            </p>
                          )}
                          <div></div>
                          <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
                            € {product.price}
                          </p>
                        </div>

                        <div>
                          <div className="flex items-center flex-col">
                            {cartItems.some(
                              (item) => item._id === product._id
                            ) ? (
                              <div className="flex items-center gap-2">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDecrement(product._id);
                                  }}
                                  className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
                                >
                                  -
                                </button>
                                <span className="text-[#253D4E] bg-[#F3F3F3] w-[26px] h-[22px] items-center flex justify-center">
                                  {productCounts[product._id] || 1}
                                </span>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleIncrement(product._id);
                                  }}
                                  className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
                                >
                                  +
                                </button>
                              </div>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAddToCart(product);
                                }}
                                className="flex items-center justify-between py-1 px-1 w-[75px] bg-[#ACE03A] rounded-md"
                              >
                                <PiShoppingCartLight
                                  size={18}
                                  color="#040404"
                                />
                                <p className="text-[#040404] text-[14px] font-medium">
                                  Add
                                </p>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}

                {category.products.length > 0 && (
                  <div
                    key={`see-all-${category._id}`}
                    className="flex flex-col items-center justify-center cursor-pointer mt-20 ml-10"
                    onClick={() =>
                      handleSlideClick({
                        name: category.name,
                        id: category._id,
                      })
                    }
                  >
                    <p className="text-2xl font-semibold text-[#ACE03A]">
                      See All
                    </p>
                    <p className="text-sm  text-[#ADADAD] ">Click to explore</p>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        );
      })}

      <div className="mt-4 flex justify-center">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={totalPages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakClassName={"page-item"}
          pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
}

export default PeopleBuy;
