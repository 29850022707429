import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import TotalRetunOrders from "../../../components/TotalReturnOrders/TotalReturnOrders";
import ReturnRecentOrders from "../../../components/ReturnRecentOrders/ReturnRecentOrders";

function OrderAndReturn() {
  return (
    <>
      <TotalRetunOrders />
      <ReturnRecentOrders />
    </>
  );
}

export default OrderAndReturn;
