import React, { useState, useRef, useEffect } from "react";
import AddItems from "../../../assets/images/addItems.png";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../../api/apiService";
import { ENDPOINTS } from "../../../api/apiRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddItem() {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);

  const [itemName, setItemName] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [units, setUnits] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [status, setStatus] = useState("active");
  const [retailPrice, setRetailPrice] = useState("");
  const [price, setPrice] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const { item } = location.state || {};
  useEffect(() => {
    if (item) {
      setItemName(item.name);
      setItemNumber(item.itemNo);
      setItemDescription(item.description);
      setQuantity(item.quantity);
      setPrice(item.price);
      setRetailPrice(item.retailPrice);
      setCategoryId(item.category?.id || "");
      setStatus(item.isActive ? "active" : "inactive");
    }
  }, [item]);


  const getToken = () => localStorage.getItem("token");

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await API.get(ENDPOINTS.GET_CATEGORIES, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(response.categories || []);
      } catch (err) {
        console.error("Error fetching categories:", err);
        setCategories([]);
      }
    };

    const fetchSubCategories = async () => {
      const token = getToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await API.get(ENDPOINTS.GET_SUBCATEGORIES, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubCategories(response || []);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        setSubCategories([]);
      }
    };

    fetchCategories();
    fetchSubCategories();
  }, []);

  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const handleSave = async () => {
    const token = getToken();
    if (!token) {
      console.error("No token found");
      return;
    }

    const formData = new FormData();
    formData.append("name", itemName);
    formData.append("price", price);
    formData.append("retailPrice", retailPrice);
    formData.append("description", itemDescription);
    formData.append("quantity", quantity);
    formData.append("unitType", units);
    formData.append("category", categoryId);
    formData.append("subcategory", subCategoryId);
    formData.append("isActive", status === "active");

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    setIsLoading(true);

    try {
      let response;

      if (item && item._id) {
      
        response = await API.put(
          `${ENDPOINTS.UPDATE_INVENTORIES}/${item._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
      
        response = await API.post(ENDPOINTS.CREATE_INVENTORIES, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (response) {
        toast.success("Product added successfully!");
        navigate("/admin/inventory");
      }
    } catch (error) {
      console.error("Error saving item:", error);
      toast.error("Failed to save the product.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-8 w-full">
      <div className="md:flex justify-between w-full flex-wrap gap-2">
        <div>
          <h1 className="text-4xl font-bold">Add Menu Item</h1>
          <p className="text-xl">
            Here is a quick overview about your restaurant’s Menu
          </p>
        </div>
        <div className="flex gap-2 h-fit self-end">
          <button   onClick={() => navigate(-1)} className="py-2 text-[#003E00] rounded-xl font-semibold px-3">
            Cancel
          </button>
          <button
            className="bg-[#003E00] py-2 text-white rounded-xl font-semibold px-3"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
      <div className="mt-10 md:flex w-full gap-8">
        <div className="md:w-[70%]">
          <img
            src={selectedFile ? URL.createObjectURL(selectedFile) : AddItems}
            className="rounded-md"
            alt="addItems"
          />
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />
          <button
            type="button"
            className="bg-[#003E00] text-white py-2 px-4 rounded-xl mt-2"
            onClick={() => fileInputRef.current.click()}
          >
            Upload Image
          </button>
        </div>
        <div className="flex flex-col gap-6 md:mt-0 mt-10 w-full">
          <div className="flex md:flex-row flex-col gap-5 w-full">
            <div className="flex flex-col w-full">
              <label className="font-semibold">Item Name</label>
              <input
                type="text"
                className="p-3 rounded-xl border w-full outline-none"
                placeholder="Snacks"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-semibold">Price</label>
              <input
                type="number"
                className="p-3 rounded-xl border w-full outline-none"
                placeholder="$"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-semibold">Retail Price</label>
              <input
                type="number"
                className="p-3 rounded-xl border w-full outline-none"
                placeholder="$"
                value={retailPrice}
                onChange={(e) => setRetailPrice(e.target.value)}
              />
            </div>
          </div>
          <div>
            <label className="font-semibold">Item Description</label>
            <textarea
              className="p-3 rounded-xl border w-full outline-none"
              placeholder="Describe your snacks here"
              rows="5"
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
            />
          </div>
          <div className="flex md:flex-row flex-col  gap-5 w-full">
            <div className="flex flex-col w-full">
              <label className="font-semibold">Quantity</label>
              <input
                type="number"
                className="p-3 rounded-xl border w-full outline-none"
                placeholder="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-semibold">No. of Units</label>
              <select
                className="p-3 rounded-xl border w-full outline-none"
                value={units}
                onChange={(e) => setUnits(e.target.value)}
              >
                <option value="" disabled>
                  Select Units
                </option>
                <option value="kg">Kilogram</option>
                <option value="gm">Gram</option>
                <option value="ml">Milliliters</option>
                <option value="lt">Liter</option>
                <option value="pieces">Pieces</option>
              </select>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-5 w-full">
            <div className="flex flex-col w-full mb-2 md:mb-0">
              <label className="font-semibold">Choose Category</label>
              <select
                className="p-3 rounded-xl border w-full outline-none"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option value="" disabled>
                  Choose category
                </option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full">
              <label className="font-semibold">Choose SubCategory</label>
              <select
                className="p-3 rounded-xl border w-full outline-none"
                value={subCategoryId}
                onChange={(e) => setSubCategoryId(e.target.value)}
              >
                <option value="" disabled>
                  Choose subcategory
                </option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory._id} value={subCategory._id}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className="flex flex-col w-full">
              <label className="font-semibold">Item Status</label>
              <select
                className="p-3 rounded-xl border w-full outline-none"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItem;
