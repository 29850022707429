import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { ENDPOINTS } from "../../api/apiRoutes";
import API from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import { FaDownload } from "react-icons/fa6";
import { jsPDF } from "jspdf";
import logo from "../../assets/images/apnadookan.png";


function OrderTracking() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const ordersPerPage = 10;
  const navigate=useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const userId = localStorage.getItem("_id");
        if (!userId) {
          throw new Error("Please Enter your details to Login.");
        }
        const res = await API.get(`${ENDPOINTS.GET_USERORDER}/${userId}`);
        setOrders(res.orders);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to fetch orders.");
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);


  // const generateInvoice = (order, callback) => {
  //   const doc = new jsPDF();
  
  //   const img = new Image();
  //   img.src = logo;
  
  //   img.onload = () => {
  //     const logoWidth = 30;
  //     const logoHeight = 30;
  //     const pageWidth = doc.internal.pageSize.getWidth();
  
  //     doc.addImage(img, "PNG", 10, 10, logoWidth, logoHeight);
  //     doc.setFont("helvetica", "normal");
  //     doc.setFontSize(12);
  //     const detailsY = 15;
  //     const details = [
  //       "Apnadookan",
  //       "Klaneettitie 13, 00420 Helsinki, Finland",
  //       "+358 45 2069311 (WhatsApp)",
  //       "apnadookan@gmail.com",
  //     ];
  
  //     details.forEach((line, index) => {
  //       const textX = pageWidth - 10 - doc.getTextWidth(line);
  //       doc.text(line, textX, detailsY + index * 5);
  //     });
  
  //     doc.setFont("helvetica", "bold");
  //     doc.setFontSize(16);
  //     doc.text("Invoice", pageWidth / 2, 50, null, null, "center");
  
  //     doc.setFont("helvetica", "bold");
  //     doc.setFontSize(12);
  //     doc.text("Order Details:", 10, 60);
  
  //     doc.setFont("helvetica", "normal");
  
  //     const orderDetails = [
  //       `Customer Name: ${order.shippingAddress.Address.fullName}`,
  //       `Order ID: ${order._id}`,
  //       `Address: ${order.shippingAddress.Address.street}, ${order.shippingAddress.Address.city}`,
  //       `Payment Type: COD`,
  //        `Drop Off Instructions: ${order.shippingAddress.DropOffInstructions}, ${order.shippingAddress.DropOffInstructions}`,
  //     ];
  
  //     orderDetails.forEach((line, index) => {
  //       doc.text(line, 10, 70 + index * 8);
  //     });
  
  //     // Align Payment Date at the right side
  //     const paymentDate = `Payment Date: ${new Date(order.paidAt).toLocaleDateString()}`;
  //     const paymentDateX = pageWidth - 10 - doc.getTextWidth(paymentDate);
  //     doc.text(paymentDate, paymentDateX, 70);
  
  //     doc.setFont("helvetica", "bold");
  //     const tableStartY = 130;
  //     const colHeaders = ["Product", "Quantity", "Price"];
  //     const colWidths = [130, 30, 30];
  //     const rowHeight = 10;
  //     let xPos = 10;
  
  //     colHeaders.forEach((header, index) => {
  //       doc.text(header, xPos + 2, tableStartY + 7);
  //       doc.rect(xPos, tableStartY, colWidths[index], rowHeight);
  //       xPos += colWidths[index];
  //     });
  
  //     let rowStartY = tableStartY + rowHeight;
  //     doc.setFont("helvetica", "normal");
  //     order.cart.forEach((item) => {
  //       xPos = 10;
  //       const rowData = [`${item.name}`, `${item.quantity}`, `€${item.price}`];
  //       rowData.forEach((data, index) => {
  //         doc.text(data, xPos + 2, rowStartY + 7);
  //         doc.rect(xPos, rowStartY, colWidths[index], rowHeight);
  //         xPos += colWidths[index];
  //       });
  //       rowStartY += rowHeight;
  //     });
  
  //     // Add Delivery Charges and Other Charges above Total Price
  //     const chargesY = rowStartY + 10;
  //     doc.setFont("helvetica", "normal");
  //     doc.text(`Delivery Charges: €${order.otherPrice.deliveryCharges}`, 10, chargesY);
  //     doc.text(`Other Charges: €${order.otherPrice.otherCharges}`, 10, chargesY + 8);
  
  //     // Total Price below Delivery Charges and Other Charges
  //     const totalPriceY = chargesY + 20;
  //     doc.setFont("helvetica", "bold");
  //     doc.text(`Total Price: €${order.totalPrice}`, 10, totalPriceY);
  
  //     // Convert the PDF to Blob and open it in a new tab
  //     const pdfBlob = doc.output("blob");
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     window.open(pdfUrl, "_blank");
  
  //     if (callback) callback();
  //   };
  // };

  const generateInvoice = (order, callback) => {
    const doc = new jsPDF();
  
    const img = new Image();
    img.src = logo;
  
    img.onload = () => {
      const logoWidth = 30;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.getWidth();
  
      doc.addImage(img, "PNG", 10, 10, logoWidth, logoHeight);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      const detailsY = 15;
      const details = [
        "Apnadookan",
        "Klaneettitie 13, 00420 Helsinki, Finland",
        "+358 45 2069311 (WhatsApp)",
        "apnadookan@gmail.com",
      ];
  
      details.forEach((line, index) => {
        const textX = pageWidth - 10 - doc.getTextWidth(line);
        doc.text(line, textX, detailsY + index * 5);
      });
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Invoice", pageWidth / 2, 50, null, null, "center");
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text("Order Details:", 10, 60);
  
      doc.setFont("helvetica", "normal");
  
      // Wrap long lines to fit within the page width
      const orderDetails = [
        `Customer Name: ${order.shippingAddress.Address.fullName}`,
        `Order ID: ${order._id}`,
        `Address: ${order.shippingAddress.Address.street}, ${order.shippingAddress.Address.city}`,
        `Drop Off Instructions: ${order.shippingAddress.DropOffInstructions}`,
         `Payment Type: COD`,
      ];
  
      let currentY = 70;
      orderDetails.forEach((line) => {
        const maxWidth = pageWidth - 20; // Leave some margin from the page edges
        const splitText = doc.splitTextToSize(line, maxWidth);
        doc.text(splitText, 10, currentY);
        currentY += splitText.length * 8; // Adjust Y-position based on number of lines
      });
  
      // Payment Date remains in the same position
      const paymentDate = `Payment Date: ${new Date(order.paidAt).toLocaleDateString()}`;
      const paymentDateX = pageWidth - 10 - doc.getTextWidth(paymentDate);
      doc.text(paymentDate, paymentDateX, 70); // Keep it at Y = 70
  
      doc.setFont("helvetica", "bold");
      const tableStartY = currentY + 20; // Start the table after the last order detail
      const colHeaders = ["Product", "Quantity", "Price"];
      const colWidths = [130, 30, 30];
      const rowHeight = 10;
      let xPos = 10;
  
      colHeaders.forEach((header, index) => {
        doc.text(header, xPos + 2, tableStartY + 7);
        doc.rect(xPos, tableStartY, colWidths[index], rowHeight);
        xPos += colWidths[index];
      });
  
      let rowStartY = tableStartY + rowHeight;
      doc.setFont("helvetica", "normal");
      order.cart.forEach((item) => {
        xPos = 10;
        const rowData = [`${item.name}`, `${item.quantity}`, `€${item.price}`];
        rowData.forEach((data, index) => {
          doc.text(data, xPos + 2, rowStartY + 7);
          doc.rect(xPos, rowStartY, colWidths[index], rowHeight);
          xPos += colWidths[index];
        });
        rowStartY += rowHeight;
      });
  
      // Add Delivery Charges and Other Charges above Total Price
      const chargesY = rowStartY + 10;
      doc.setFont("helvetica", "normal");
      doc.text(`Delivery Charges: €${order.otherPrice.deliveryCharges}`, 10, chargesY);
      doc.text(`Other Charges: €${order.otherPrice.otherCharges}`, 10, chargesY + 8);
  
      // Total Price below Delivery Charges and Other Charges
      const totalPriceY = chargesY + 20;
      doc.setFont("helvetica", "bold");
      doc.text(`Total Price: €${order.totalPrice}`, 10, totalPriceY);
  
      // Convert the PDF to Blob and open it in a new tab
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
  
      if (callback) callback();
    };
  };
  
  
  
  
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const displayedOrders = orders.slice(
    currentPage * ordersPerPage,
    (currentPage + 1) * ordersPerPage
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-2xl">No oder Found!</div>;

  return (
    <div className="w-[80%] mx-auto mt-10">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr className="border-b border-gray-300">
              <th className="border border-gray-300 px-4 py-2 text-left">
                Date
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Order-ID
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Total Amount
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Status
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left"></th>
              <th className="border border-gray-300 px-4 py-2 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order, index) => (
              <tr key={index} className="border-b border-gray-300">
                <td className="border border-gray-300 px-4 py-2">
                  {" "}
                  {new Date(order.createdAt).toLocaleDateString()}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {order._id}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {order.totalPrice}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {order.status}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button className="bg-[#ACE03A] py-1 px-4 rounded-md"  onClick={() => navigate(`/orderDetails/${order?._id}`, { state: { id: order._id } })}>View</button>
                </td>
                <td className="border border-gray-300 px-4 py-2">
                                  <button onClick={() => generateInvoice(order)}>
                                      <FaDownload size={18} color="#003E00" />
                                    </button>
                                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={Math.ceil(orders.length / ordersPerPage)}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default OrderTracking;
