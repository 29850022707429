import React from "react";
import "../../index.css";
import ordericon from "../../assets/images/ordericon.png";
import arrowdown from "../../assets/images/arrowdown.png";
import arrowup from "../../assets/images/arrowup.png";
import "../../index.css";
import { useNavigate } from "react-router-dom";

function TotalOrders() {
  const navigate = useNavigate();
  return (
    <>
      <div className="md:px-10 px-8 md:py-10 py-5">
        <div className="flex md:gap-10 gap-6 flex-col">
          <div className="flex flex-wrap justify-between items-center gap-4 ">
            <div className="flex flex-col gap-2">
              <p className="text-[#020617] text-4xl BBoldFont ">Inventory</p>
              <p className="text-[#696969] text-base NormalFont">
                Here is a quick overview about your Inventory
              </p>
            </div>
            <div className="flex gap-3">
              <div></div>
              <div>
                <button
                  onClick={() => navigate("add-items")}
                  className="bg-[#003E00] text-sm inter-Semi text-white p-[10px] rounded-md"
                >
                  Add Item
                </button>
              </div>
            </div>
          </div>
          {/* <div className="flex md:flex-row flex-wrap  md:gap-8 gap-4">
            <div className="flex flex-col gap-3  rounded-3xl  p-6 md:w-[260px] md:h-[170px] cardshadow">
              <div className="flex gap-2">
                <img src={ordericon} alt="" />
                <p className="quicksand-Medium text-base">Total Items</p>
              </div>

              <div className="flex  flex-col ">
                <div className="flex gap-2 items-center">
                  <p className="text-[42px] text-[#0C4259] quicksand-SemiBold">
                    150
                  </p>
                  <img src={arrowdown} alt="" />
                </div>
                <div>
                  <p className="text-[#7A99A6] text-xs quicksand-Medium">
                    1.3% vs last month
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3  rounded-3xl  p-6 md:w-[260px] md:h-[170px] cardshadow">
              <div className="flex gap-2">
                <img src={ordericon} alt="" />
                <p className="quicksand-Medium text-base">Available Items</p>
              </div>

              <div className="flex  flex-col ">
                <div className="flex gap-2 items-center">
                  <p className="text-[42px] text-[#0C4259] quicksand-SemiBold">
                    20
                  </p>
                  <img src={arrowup} alt="" />
                </div>
                <div>
                  <p className="text-[#7A99A6] text-xs quicksand-Medium">
                    8.3% vs last month
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3  rounded-3xl  p-6 md:w-[260px] md:h-[170px] cardshadow">
              <div className="flex gap-2">
                <img src={ordericon} alt="" />
                <p className="quicksand-Medium text-base">Out of Stock Items</p>
              </div>

              <div className="flex  flex-col ">
                <div className="flex gap-2 items-center">
                  <p className="text-[42px] text-[#0C4259] quicksand-SemiBold">
                    5
                  </p>
                  <img src={arrowup} alt="" />
                </div>
                <div>
                  <p className="text-[#7A99A6] text-xs quicksand-Medium">
                    Tenants this month
                  </p>
                </div>
              </div>
            </div>
           
          </div> */}
        </div>
      </div>
    </>
  );
}

export default TotalOrders;
